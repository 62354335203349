<div *ngIf="web">
    <div class="desktop" *ngIf="isMenuOpen('inicio')">
        <app-menu-principal [usuario]="userJson"></app-menu-principal>
    </div>
    <div class="desktop" *ngIf="isMenuOpen('serviciotecnico')">
        <app-menu-principal [usuario]="userJson"></app-menu-principal>
    </div>
    <div class="desktop" *ngIf="isMenuOpen('tallermovil')">
        <app-menu-principal [usuario]="userJson"></app-menu-principal>
    </div>
    <div class="desktop" *ngIf="isMenuOpen('takata')">
        <app-menu-principal [usuario]="userJson"></app-menu-principal>
    </div>
    <div class="desktop" *ngIf="isMenuOpen('usuario')">
        <app-menu-principal [usuario]="userJson"></app-menu-principal>
    </div>
    <div class="desktop" *ngIf="isMenuOpen('tiendavirtual')">
        <app-menu-ecommerce [usuario]="userJson" [carrito]="cantidadCarrito" [carritociudad]="ciudadPedido" [carritosucursal]="sucursalCarrito"></app-menu-ecommerce>
    </div>
    <div class="desktop" *ngIf="isMenuOpen('b2b')">
        <app-menu-b2b [usuario]="userJson" [carrito]="cantidadCarrito" [carritociudad]="ciudadPedido" [carritosucursal]="sucursalCarrito"></app-menu-b2b>
    </div>
    <div class="desktop" id="head-login" *ngIf="isMenuOpen('login')">
        <a class="navbar-brand" routerLink="">
            <img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559522/production/plataforma-toyota/web/assets/icons/comunications/logo_toyosa_negro.svg" width="145" height="35" alt="Logo toyosa" class="mx-4 mt-2 logo"/>
        </a> 
    </div>
    <!--<div class="row header-login" id="head-login" *ngIf="noMostrar">
        <a class="navbar-brand" routerLink="">
            <img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559522/production/plataforma-toyota/web/assets/icons/comunications/logo_toyosa_negro.svg" width="145" height="35" alt="Logo toyosa" class="logo"/>
        </a> 
    </div>
    <div class="menu-desktop" *ngIf="!noMostrar">
        <div id='header' class="header no-mobile">
            <div class="back-opacity" *ngIf="topHeader>0"></div>
            <div id="header-menu-desktop" #fondoMenu class="menu-fixed">
                <div class="header-menu" (mouseenter)="viewSubmenuVenta = false">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" routerLink="" *ngIf="topHeader>0">
                            <img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559522/production/plataforma-toyota/web/assets/icons/comunications/logo_toyosa_negro.svg" width="145" height="35" alt="Logo toyosa" class="logo"/>
                        </a> 
                        <a class="navbar-brand" routerLink="" *ngIf="topHeader===0">
                            <img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559522/production/plataforma-toyota/web/assets/icons/comunications/logo_toyosa_negro.svg" width="145" height="35" alt="Logo toyosa" class="logo"/>
                        </a> 
                        <div class="menu-principal_completo">
                            <div class="col-0 col-md-8 col-xl-9  navbar-nav menu-catalogo" *ngIf="mostrarCatalogo">
                                <app-menu-catalogo  class="control-ecommerce"></app-menu-catalogo>
                            </div>
                            <div class="navbar-collapse" id="navbarTogglerDemo02">
                                <ul class="col-0 col-md-8 col-xl-9 navbar-nav mt-2 justify-content-center">
                                    <li ngbDropdown class="nav-item" *ngIf="!mostrareCommerce ">
                                        <a class="nav-link item-toyota" (click)="viewSubmenu()" *ngIf="!mostrarB2B ">Vehículos nuevos</a>                  
                                    </li>
                                    <div class="menu-separator" *ngIf="!mostrareCommerce"></div>
                                    <li ngbDropdown class="nav-item" *ngIf="!mostrareCommerce">
                                        <button class="btn btn-primary btn-menu-toyosa" ngbDropdownToggle *ngIf="!mostrarB2B ">Servicio Técnico</button>
                                        <div ngbDropdownMenu>
                                            <button ngbDropdownItem (click)="onSelectOption('servicio-tecnico/cotizar-mantenimientos')">Cotizar Mantenimientos</button>
                                            <button ngbDropdownItem (click)="onSelectOption('servicio-tecnico')">Agendar Cita</button>
                                            <button ngbDropdownItem (click)="onSelectOption('taller-movil')">Taller Móvil</button>
                                            <button ngbDropdownItem (click)="onSelectOption('takata')">Recall Takata</button>
                                        </div>
                                    </li>
                                    <div class="menu-separator" *ngIf="!mostrareCommerce"></div>
                                    <li ngbDropdown class="nav-item" *ngIf="!mostrareCommerce">
                                        <a class="nav-link item-toyota" routerLink='/tienda-virtual' id="mundotoyota" (click)="noviewSubmenu()" *ngIf="!mostrarB2B "><img src="../../assets/icons/price_tag-01.svg" width="20" height="18" class="mini-tag"/> E-commerce</a>
                                    </li>
                                    
                                    <li class="nav-item nav-search" *ngIf="mostrareCommerce">
                                        <app-menu-ecommerce  class="control-ecommerce"></app-menu-ecommerce>
                                    </li>
                                    <li class="nav-item nav-search" *ngIf="mostrarB2B">
                                        <app-menu-b2b  class="control-ecommerce"></app-menu-b2b>
                                    </li> 
                                </ul>
                                <ul class="navbar-nav mt-2" *ngIf="mostrareCommerce">
                                    <li class="nav-item nav-cart">
                                        <a routerLink="/tienda-virtual/carrito"><img src="../../assets/icons/carrito.svg" class="carrito-img"/><span class="badge badge-pt">{{cantidadCarrito}}</span></a>
                                        <div class="sucursal">{{sucursalCarrito}}</div>
                                    </li>
                                </ul>
                                <ng-template #userData>
                                    <ul class="col-2 navbar-nav mt-2 justify-content-center menu-profile">
                                        <li ngbDropdown class="nav-item">
                                            <a class="nav-link item-profile" routerLink="/usuario/perfil">{{nombreUsuario}} <img src="../../assets/icons/profile-user.svg" class="img-profile"/></a>                  
                                        </li>
                                    </ul>
                                </ng-template>
                                <ul class="col-2 navbar-nav mt-2 justify-content-center" *ngIf="!userJson; else userData">
                                    <li ngbDropdown class="nav-item">
                                        <a class="nav-link item-toyota" routerLink="/usuario/registro" (click)="viewModels()">Registro</a>                  
                                    </li>
                                    <div class="menu-separator"></div>
                                    <li ngbDropdown class="nav-item">
                                        <a class="nav-link item-toyota" routerLink="/login" (click)="viewModels()">Login</a>                  
                                    </li>
                                </ul>
                            </div>
                        </div>   
                    </nav>
                </div>
                <div class="submenu-plataforma" *ngIf="viewSubmenuVenta">
                    <div class="container">
                        <div class="row">
                            <nav class="navbar navbar-expand-lg navbar-light justify-content-center">
                                <div class="navbar-nav">
                                    <a class="dropdown-item nav-link item-plataforma" href="https://www.toyota.bo/" target="_blank" (click)="noviewSubmenu()">Toyota  <img src="../../assets/icons/external-link-white.svg" class="icono-link-externo"/></a>
                                    <a class="dropdown-item nav-link item-plataforma" href="https://www.lexus.com.bo/es.html" target="_blank" (click)="noviewSubmenu()">Lexus  <img src="../../assets/icons/external-link-white.svg" class="icono-link-externo"/></a>
                                </div>
                            </nav>
                        </div>
                    </div>
                    
                </div>
                
            
            </div>
        </div>
    </div>-->
    
    <app-mobile class="mobile" id="menu-mobile"></app-mobile>
</div>



<!--<ng-template #contentcp let-c="close" let-d="dismiss" >
        <div class="modal-body">
            <h4 class="txt-title">¿En dónde recogerás tus compras?</h4>
            <div class="txt-relevant">
                Te ofrecemos una varidad de repuestos originales para tu vehículo.
            </div>
            <div class="row ciudades">
                <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                    <label class="ciudades-lbl">Departamentos :</label> 
                    <select placeholder="Seleccione un departamento" (change)="selectCiudad($event)" class="ciudades-select">
                        <option *ngFor="let item of ciudadesTiendas; index as i"  [value]="item['id_departamento']">
                            {{item['nombre_departamento']}}
                        </option>
                    </select>
                </div>
                <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                    <form [formGroup]="formulario" class="row pedido">
                        <label class="ciudades-lbl">Sucursales :</label>
                        <select class="ciudades-select" formControlName="sucursal">
                            <option *ngFor="let sucursal of dataCompletaBolivia" [value]="sucursal['id_sucursal']"><span class="suc-nombre">{{sucursal['nombre_sucursal']}}</span> - {{sucursal['direccion']}}</option>
                        </select>
                    </form>
                </div>
                <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <a (click)="guardar()" class="save">Guardar</a>
                </div>
            </div>
            <div class="row" *ngIf="dataCompletaBolivia?.length > 0">
                <a class="view-map" (click)="viewMap()" *ngIf="verMapa"><img src="../../../assets/icons/sucursales.svg" class="pin-mapas"/> Ocultar mapa</a>
                <a class="view-map" (click)="viewMap()" *ngIf="!verMapa"><img src="../../../assets/icons/sucursales.svg" class="pin-mapas"/> Ver mapa</a>
            </div>
            <div *ngIf="verMapa">
                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                    <agm-marker *ngFor="let sucursal of dataCompletaBolivia; index as i" [latitude]="sucursal['latitud']" [longitude]="sucursal['longitud']" [markerClickable]="true" (markerClick)="activarSucursal(dataCompletaBolivia[i])">
                        <agm-info-window>
                            <strong>{{sucursal['nombre_sucursal']}}</strong>
                        </agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>
        </div>          
    </ng-template>    
-->


<ng-template #brand let-modal id="brand">
    <div class="modal-header">
      <h4 class="modal-title">En qué ciudad te encuentras?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
            <a class="col-12 city-type-link" (click)="enableDisableCiudades(0, '')" [style.background-color]="toggle[0] ? 'transparent' : '#171a20'" [style.color]="toggle[0] ? '#171a20' : '#ffffff'">La Paz</a>
            <a class="col-12 city-type-link" (click)="enableDisableCiudades(1, '')" [style.background-color]="toggle[1] ? 'transparent' : '#171a20'" [style.color]="toggle[1] ? '#171a20' : '#ffffff'">Cochabamba</a>
            <a class="col-12 city-type-link" (click)="enableDisableCiudades(2, '')" [style.background-color]="toggle[2] ? 'transparent' : '#171a20'" [style.color]="toggle[2] ? '#171a20' : '#ffffff'">Santa Cruz</a>
            <a class="col-12 city-type-link" (click)="enableDisableCiudades(3, '')" [style.background-color]="toggle[3] ? 'transparent' : '#171a20'" [style.color]="toggle[3] ? '#171a20' : '#ffffff'">Oruro</a>
            <a class="col-12 city-type-link" (click)="enableDisableCiudades(4, '')" [style.background-color]="toggle[4] ? 'transparent' : '#171a20'" [style.color]="toggle[4] ? '#171a20' : '#ffffff'">Potosí</a>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
    </div>
  </ng-template>