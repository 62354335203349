import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavHeaderRoutingModule } from './nav-header-routing.module';
import { NavHeaderComponent } from './nav-header.component';
import { MobileComponent } from './mobile/mobile.component';
import { NgbDropdownModule, NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuEcommerceComponent } from './menu-ecommerce/menu-ecommerce.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuCatalogoComponent } from './menu-catalogo/menu-catalogo.component';
import { PedidoEnvioComponent } from './pedido-envio/pedido-envio.component';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  exports: [NavHeaderComponent],
  declarations: [
    NavHeaderComponent,
    MobileComponent,
    MenuEcommerceComponent,
    MenuCatalogoComponent,
    PedidoEnvioComponent
  ],
  imports: [
    CommonModule,
    NavHeaderRoutingModule,
    NgbDropdownModule, NgbCollapseModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBk6-zusArk27Q-a6ZPp44iLFmQG_ckXSA'
    })
  ]
})
export class NavHeaderModule { }
