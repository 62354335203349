<div class="takata-page">
    <div class="container">
        <div class="row">

            <div class="col-11 col-sm-12 col-md-6 col-lg-6 col-xl-6 takata-data">

                <!--<div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 takata-media" style="margin-top: 0px; margin-bottom: 20px;">
                        <img src="../../../assets/images/campañas/takata/banner_takata.webp" class="takata-img"/>
                    </div>
                </div>-->
                <div class="takata-box">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 takata-media" style="margin-top: 0px; margin-bottom: 20px;">
                            <img src="../../../assets/images/campañas/takata/1.webp" class="takata-img"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 takata-info">
                            <p> Consulta el <strong>CHASIS</strong> o <strong>PLACA</strong> de tu vehículo para saber si necesita una revisión de sus bolsas de aire
                            </p>
                        </div>
                    </div>
                    <div>
                        <form [formGroup]="formulario" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-takata">
                            <div class="row">
                                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 search-placa" >
                                    <input formControlName="placa" class="form-control input-placa" [(ngModel)]="placavehiculo" type="text" placeholder="PLACA / CHASIS">
                                    <a (click)="buscarvehiculo()" class="btn-search">Consultar</a>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 takata-media">
                          <img src="../../../assets/images/campañas/takata/2.webp" class="takata-img"/>
                      </div>
                    </div>
                </div>
                
                <!--
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 takata-media desktop" style="margin-top: 0px;">
                        <img src="../../../assets/images/campañas/takata/banner2_takata.webp" class="takata-img"/>
                        <img src="../../../assets/images/campañas/takata/banner3_takata.webp" class="takata-img"/>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 takata-media mobile" style="margin-top: 0px;">
                        <img src="../../../assets/images/campañas/takata/banner2_takata.webp" class="takata-img"/>
                        <img src="../../../assets/images/campañas/takata/banner3_takata.webp" class="takata-img"/>
                </div>
                </div>
                -->
            </div>
        </div>


        <!--
        <div class="row">
            <div class="col-11 col-sm-12 col-md-6 col-lg-6 col-xl-6 takata-campania">
                <div class="row">
                    Cada persona que se apersone a realizar su revisión de Airbag o bolsa de aire en nuestros talleres a nivel nacional o
                     mediante nuestro servicio de taller móvil se lo premiará con un vale de 100 Bs. de gasolina especial.
                </div>

                <div class="takatacampa-media desktop" style="margin-top: 30px; margin-bottom: 40px;">
                    <img src="../../../assets/images/campañas/takata/banner_3.webp" class="takata-img"/>
                </div>
                <div class="takatacampa-media mobile" style="margin-top: 30px; margin-bottom: 40px;">
                    <img src="../../../assets/images/campañas/takata/banner3-mobile.webp" class="takata-img"/>
                </div>
            </div>
        </div>
    -->
        <div class="row terminos-condiciones">
            <div class="col-11 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="terminos-contenido">
                    <h2>TÉRMINOS Y CONDICIONES</h2>
                    <p class="sub-text">PROYECTO DE DESARROLLO DE CAMPAÑA DE RESPONSABILIDAD SOCIAL</p>
                    <ol>
                        <li> <strong>Nombre de la Promoción Empresarial</strong>
                            <p>Campaña de “Tu seguridad vale”</p>
                        </li>
                        <li>
                            <strong>Objetivo</strong>
                            <p>Toyota Motors Corporation con sus marcas Toyota & Lexus y la Distribuidora Toyosa tienen por misión velar por la seguridad de sus usuarios.</p>
                            <p>La marca dentro de sus pruebas de calidad ha detectado falencias en uno de los componentes del sistema de Bolsas de Aires (Airbags), falencia que puede derivar en lesiones o inclusive riesgo de muerte de los ocupantes.</p>
                            <p>Para prevenir esta contingencia de seguridad, y asegurar que los Airbags tengan un correcto funcionamiento, Toyosa inspeccionará las piezas y de ser necesario las reemplazará.</p>
                            <p>Para lograr este objetivo, se realizará una convocatoria a revisión GRATUITA en talleres de Toyosa de todos los clientes que actualmente están usando las unidades afectadas.</p>
                            <p>Esta campaña está basada en la RESPONSABILIDAD SOCIAL que tenemos con el cliente de Toyota, captando la mayor cantidad de personas que podamos para revisar su vehículo y asegurar el correcto funcionamiento de su sistema bolsas de aire.</p>
                            <p>Se quiere motivar al cliente otorgando un vale de 100 Bs. en gasolina especial una vez visite nuestra red de talleres a nivel nacional a realizar su inspección de bolsas de aire.</p>
                        </li>
                        <li><strong>Solicitud de Autoridad del Juego</strong>
                            <p>Toyota Motors Corporation con sus marcas Toyota & Lexus y la Distribuidora Toyosa tienen por misión velar por la seguridad de sus usuarios.</p>
                            <p>La marca dentro de sus pruebas de calidad ha detectado falencias en uno de los componentes del sistema de Bolsas de Aires (Airbags), falencia que puede derivar en lesiones o inclusive riesgo de muerte de los ocupantes.</p>
                            <p>Para prevenir esta contingencia de seguridad, y asegurar que los Airbags tengan un correcto funcionamiento, Toyosa inspeccionará las piezas y de ser necesario las reemplazará.</p>
                            <p>Para lograr este objetivo, se realizará una convocatoria a revisión GRATUITA en talleres de Toyosa de todos los clientes que actualmente están usando las unidades afectadas.</p>
                            <p>Esta campaña está basada en la RESPONSABILIDAD SOCIAL que tenemos con el cliente de Toyota, captando la mayor cantidad de personas que podamos para revisar su vehículo y asegurar el correcto funcionamiento de su sistema bolsas de aire.</p>
                            <p>Se quiere motivar al cliente otorgando un vale de 100 Bs. en gasolina especial una vez visite nuestra red de talleres a nivel nacional a realizar su inspección de bolsas de aire.</p>
                        </li>
                        <li>
                            <strong>Periodo de Duración y Lugar</strong>
                            <p>La promoción estará vigente del 1 de mayo del 2024 al 31 de diciembre del 2025 en las ciudades de:</p>
                            <ul>
                                <li>La Paz</li>
                                <li>El Alto</li>
                                <li>Cochabamba</li>
                                <li>Santa Cruz</li>
                                <li>Oruro</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Modalidad de otorgación de vales: </strong>
                            <ol>
                                <li>La modalidad de premiación es de un vale de 100 bs. de gasolina especial a cada persona que vaya a realizar su revisión de Airbag o bolsa de aire, solo será aplicable a las personas que traigan los vehículos con chasis que estén identificados en una base de datos que tenemos disponible en línea.</li>
                                <li>Especificación o mecánica:
                                    <ul>
                                        <li>La convocatoria será solo a las unidades afectadas, que están plenamente identificadas en la base de datos de Toyosa, dicha base también estará disponible para los clientes en la página web de Toyosa.</li>
                                        <li>Las personas en general puede ingresar a esta página web https://www.toyosa.com/takata o llamar al Contact Center 800-175200 para consultar si su vehículo está dentro de esta campaña de Revisión de Airbag o bolsas de aire, colocando su número de placa o chasis del vehículo.</li>
                                        <li>En la página web mencionada en el anterior punto se tendrá:
                                            <ul>
                                                <li>Las bases y condiciones de esta actividad.</li>
                                                <li>Las direcciones de los talleres Toyosa a realizar la inspección de airbags o bolsas de aire. </li>
                                                <li>Las direcciones de las estaciones de servicios de canje de los vales de gasolina. </li>
                                            </ul>
                                        </li>
                                        <li>Los modelos involucrados son en Toyota:
                                            <ul>
                                                <li>Hilux del año 2005 al 2014</li>
                                                <li>RAV4 desde el 2003 hasta el 2005</li>
                                                <li>COROLLA desde el 2002 al 2014</li>
                                                <li>YARIS desde el 2002 al 2013</li>
                                                <li>4RUNNER desde el 2011 al 2016</li>
                                            </ul>
                                        </li>
                                        <li>para Lexus son:
                                            <ul>
                                                <li>GX460 desde 2012 al 2015</li>
                                                <li>IS 250 del 2010</li>
                                                <li>IS CONVERTIDOR del 2010</li>
                                            </ul>
                                        </li>
                                        <li>Las unidades que no fueron importadas por TOYOSA pero son de marca Toyota y Lexus pueden participar de esta campaña. </li>
                                        <li>Cada persona que se acerqué a nuestra red de Talleres TOYOSA y pida un servicio para realizar la inspección de su Airbag o bolsa de aire obtendrá un vale por 100Bs. de gasolina especial de manera gratuita luego de la inspección realizada.</li>
                                        <li>El costo de la inspección de los airbags o bolsas de aire es de cero bolivianos. </li>
                                        <li>Una vez la persona recoja su vehículo en el Servicio Técnico, se registrará un vale con la fecha de entrega.</li>
                                        <li>Al entregar un vale a la persona, este tendrá vigencia de uso por 90 días, pasado ese periodo caducará, sin reembolso o devolución alguna.</li>
                                        <li>Toyosa no devolverá y reembolsará algún tipo de valor al cliente ante el extravío o daño del vale de gasolina especial otorgado.</li>
                                        <li>Toyosa no percibirá bajo ningún concepto un beneficio comercial con esta campaña, dado que el objetivo principal es realizar esta campaña como Responsabilidad Social.</li>
                                        <li>Toyota Motors Corporation y Toyosa tiene como misión velar por la seguridad de nuestros clientes y mantener la calidad de nuestros productos con este tipo de acciones.</li>
                                        <li>Toyosa asumirá el costo del total de los vales emitidos hacía los clientes.</li>
                                        <li>Los puntos habilitados para el recojo de los vales de combustible para los clientes serán únicamente en la red de talleres Toyosa.</li>
                                        <li>Se deberá tener registro de cada uno de los Vales otorgados con el nombre de la persona y sus datos junto a su orden de reparación del taller que realizo el trabajo. Los datos que se debe registrar son: nombre completo, número de cedula de identidad, lugar y fecha de entrega, premio entregado, placa o número de chasis. Se debe entregar un informe mensual de estos datos a la Autoridad de Fiscalización del Juego (AJ).</li>
                                        <li>Los vales son solo para el combustible de gasolina especial y serán intransferible a otra persona o vehículo.</li>
                                        <li>Es importante recalcar que el vale entregado de gasolina especial solo será canjeable en la ciudad que se le otorgó dicho vale, a excepción de la ciudad de El Alto que puede canjear en la ciudad de La Paz, dado que no hay un proveedor de gasolina autorizado en El Alto. </li>
                                        <li>Se tiene proyectado emitir vales de consumo de combustible en las siguientes ciudades: 
                                            <ul>
                                                <li>Santa Cruz</li>
                                                <li>La Paz</li>
                                                <li>Cochabamba</li>
                                                <li>Oruro</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Lugar entrega de premio (vales): </strong>
                            <p>Lugar: Todos los Talleres de Toyosa de la ciudad de La Paz, El Alto, Santa Cruz, Cochabamba y Oruro</p>
                            <p>Etapa de participación y fecha de entrega de premios: 1 de mayo del 2024 al 31 de diciembre del 2025. </p>
                            <table class="campania-table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>CIUDAD</th>
                                        <th>SUCURSAL</th>
                                        <th>DIRECCIÓN</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>LPZ</td>
                                        <td>SUCURSAL CURVA DE HOLGUIN</td>
                                        <td>Curva de Holguín #100</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>LPZ</td>
                                        <td>EL ALTO</td>
                                        <td>Av. 6 de Marzo frente a la Aduana</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>CBB</td>
                                        <td>SUCURSAL ZENON SALINAS</td>
                                        <td>Zenón Salinas y Guillermo Vizcarra #15</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>CBB</td>
                                        <td>SUCURSAL UYUNI</td>
                                        <td>Av. Uyuni #1185 Muyurina</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>SCZ</td>
                                        <td>CENTRAL SANTA CRUZ</td>
                                        <td>Av. Cristo Redentor Km 2 ½ # 777</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>SCZ</td>
                                        <td>SUCURSAL 3 PASOS AL FRENTE</td>
                                        <td>Av. Tres pasos al frente, entre 2do y 3er anillo</td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>SCZ</td>
                                        <td>SUCURSAL LEXUS</td>
                                        <td>Av. La Salle entre 2do y 3er anillo</td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>ORU</td>
                                        <td>ORURO</td>
                                        <td>Calle Bolivar entre Petot y Camacho #1050</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            <p><strong>Lugar de canje de los vales de gasolina especial:</strong> El ganador podrá canjear su premio en las siguientes estaciones de Servicio, según el lugar donde se entregó vale de gasolina especial:</p>
                            <table class="campania-table">
                                <thead>
                                    <tr>
                                        <th>EMPRESA</th>
                                        <th>ESTACIONES</th>
                                        <th>NOMBRE COMERCIAL</th>
                                        <th>UBICACIÓN</th>
                                        <th>CIUDAD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="7">REFINERIA ORIENTAL S.A. <br/> BIOPETROL <br/> NIT 120149029</td>
                                        <td>CENTRAL</td>
                                        <td>ALEMANA</td>
                                        <td>AV. ALEMANA Y 2DO ANILLO</td>
                                        <td>SCZ</td>
                                    </tr>
                                    <tr>
                                        <td>SUC. 1</td>
                                        <td>CHACO GAS</td>
                                        <td>AV. VIRGEN DE COTOCA Y 2DO ANILLO</td>
                                        <td>SCZ</td>
                                    </tr>
                                    <tr>
                                        <td>SUC. 2</td>
                                        <td>ROYAL</td>
                                        <td>3ER ANILLO INTERNO FRENTE A LA CANCHA ROYAL</td>
                                        <td>SCZ</td>
                                    </tr>
                                    <tr>
                                        <td>SUC. 3</td>
                                        <td>EQUIPETROL</td>
                                        <td>4TO ANILLO FRENTE AL EX BUFALO PARK</td>
                                        <td>SCZ</td>
                                    </tr>
                                    <tr>
                                        <td>SUC. 4</td>
                                        <td>UNIVERSITARIO</td>
                                        <td>4TO ANILLO AV. PARAGUA</td>
                                        <td>SCZ</td>
                                    </tr>
                                    <tr>
                                        <td>SUC. 5</td>
                                        <td>MONTECRISTO</td>
                                        <td>AV. VIRGEN DE COTOCA 8VO ANILLO</td>
                                        <td>SCZ</td>
                                    </tr>
                                    <tr>
                                        <td>SUC. 6</td>
                                        <td>LUCYFER</td>
                                        <td>AV. CIRCUNVALACIÓN</td>
                                        <td>ORU</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="3">AUTOMOVIL CLUB BOLIVIANO<br/>NIT 1006827026<br/>NIT 1007921022</td>
                                        <td>CENTRAL</td>
                                        <td>SAN JORGE</td>
                                        <td>SAN JORGE AV. 6 DE AGOSTO NRO. 2993 ESQ. AV. ARCE</td>
                                        <td>LPZ</td>
                                    </tr>
                                    <tr>
                                        <td>SUC. 1</td>
                                        <td>CALACOTO</td>
                                        <td>CALACOTO - CALLE 12 ESQ. GRAL INOFUENTES - Nº 7823</td>
                                        <td>LPZ</td>
                                    </tr>
                                    <tr>
                                        <td>CENTRAL</td>
                                        <td>BLANCO GALINDO</td>
                                        <td>C. FRANCISCO PIZARRO ESQ. BLANCO GALINDO KM 1 1/2</td>
                                        <td>CBB</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>
                                <strong>Vigencia de los vales de gasolina especial.-</strong> La vigencia de cada vale es de 90 días a partir de la entrega a la persona que traiga el vehículo al taller TOYOSA para la inspección de su airbag que esté dentro del listado de la campaña de Toyota.
                            </p>
                        </li>
                        <li>
                            <strong>Vales ofertados con indicación de valor Comercial</strong>
                            <p>El vale será por 100 Bs. de consumo de gasolina especial en los surtidores de combustible mencionados.</p>
                            <table class="campania-table">
                                <thead>
                                    <tr>
                                        <th>CANTIDAD</th>
                                        <th>DETALLE</th>
                                        <th>PRECIO UNITARIO (Bs.)</th>
                                        <th>PRECIO TOTAL (Bs.)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Indeterminado</td>
                                        <td>VALE DE COMBUSTIBLE</td>
                                        <td>Bs. 100</td>
                                        <td>Indeterminado</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            <strong>Aclaración de los vales no consumidos</strong>
                            <p>Una vez concluida la promoción y pasada la vigencia de los vales de gasolina especial, si el cliente no recoge su premio Toyosa no se responsabiliza.</p>
                        </li>
                    </ol>
                </div>
            </div>

        </div>
        <div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 takata-relevant_title">
                <a href="../../../assets/images/campañas/takata/terminos_y_condiciones_takata.pdf" download>
                  <button type="button" class="btn btn-light" style="background-color: #b8b8b8;">Términos y condiciones</button>
                </a>
              </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 takata-relevant_title">
                    Llamado a revisión de las bolsas de aire Takata
                </div>
            </div>
            <h1 class="takata-title">¡SU SEGURIDAD PODRÍA ESTAR EN RIESGO!</h1>
            <div class="col-12 col-sm-12 col-md-7 col-lg-5 col-xl-5 takata-relevant">
                <hr/>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 takata-info">
                    <p> Si es dueño de un Toyota o Lexus fabricado entre 2002 y 2016, su vehículo puede estar sujeto a un Llamado a Revisión por los infladores para las bolsas de aire Takata del pasajero delantero o del contuctor. Consulte el <strong>CHASIS</strong> o <strong>PLACA</strong> de su vehículo para saber si necesita una revisión de sus bolsas de aire
                    </p>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 m-auto">

                <p class="takata-description desktop">
                    Toyota Motors Corporation con sus marcas Toyota & Lexus y la Concesionaria Toyosa tienen por misión velar por la seguridad de sus usuarios, es por esto que la marca dentro de sus pruebas de calidad
                    a detectado una falencia en uno de sus componentes del sistema de Bolsas de Aires (Airbags) de un fabricante externo de piezas llamada TAKATA, para corregir esta falla y las bolsas de aires tengan un correcto funcionamiento,
                     la marca inspeccionará las piezas y de ser necesario las reemplazará, para esto realizará una gran convocatoria a todos los clientes Toyota dueños de las unidades afectadas.
                </p>
                <p class="takata-description desktop">
                    Esta campaña está basada en la responsabilidad social que tenemos con el cliente Toyota, se requiere captar la mayor cantidad de personas que podamos para comunicar cuyas unidades puedan estar comprometidas con el correcto funcionamiento de su sistema de Bolsas de Aire (Airbag).
                </p>


            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 takata-info">

                <h2 class="takata-title">¿Cuál es el problema?</h2>
                <p> Takata entre los años 2002 y 2015 utilizaba en los infladores de sus bolsas de aire frontales un agente químico, el nitrato de amonio, pero sin acompañarlo de un agente desecante (una combinación química que le impide absorber la humedad) tras años de exposición a climas húmedos o extremos el correcto funcionamiento de estos dispositivos se ve afectado, haciendo que estos al activarse con una chispa eléctrica exploten provocando daños graves a los ocupantes del vehículo.
                </p>
            </div>
        </div>
        <hr/>
        <!--<div class="row">
            <p><strong>Información General del llamado a Revisión Takata</strong></p>
            <p>
                Toyota comenzó llamados a revisión para ciertos vehículos Toyota y Lexus equipados con infladores para bolsa de aire Takata del pasajero delantero y un modelo Toyota equipado con un inflador de bolsa de aire Takata para el conductor (mira las siguientes secciones para más detalles). Cuando se despliegan las bolsas de aire en esos vehículos, el inflador puede explotar y lanzar fragmentos metálicos cortantes a quienes van a bordo. Esto puede causar una lesión grave o incluso la muerte.
            </p>
            <p>
                Para saber si tu vehículo está involucrado en estos Llamados a Revisión, por favor usa el buscador de llamados a revisión arriba. Sigue las instrucciones para tu vehículo como se señalan. Si no hay indicación de que tu vehículo esté involucrado en algún llamado a revisión abierto, por favor ingresa tus datos en el formulario de registro para que nos pongamos en contacto en caso que tu vehículo si califique para una revisión en un futuro.
            </p>
        </div>
        <div class="row">
            <div class="col-12 col-md-10 col-xl-10 takata-media">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/x4LDPGMEnwo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>-->
        <div class="row">
            <div class="col-12 col-md-4 col-xl-4 takata-media">
                <img src="../../../assets/images/campañas/imagen_takata-01.jpg" class="takata-img"/>
            </div>
            <div class="col-12 col-md-4 col-xl-4 takata-media">
                <img src="../../../assets/images/campañas/imagen_takata-02.jpg" class="takata-img"/>
            </div>
            <div class="col-12 col-md-4 col-xl-4 takata-media">
                <img src="../../../assets/images/campañas/imagen_takata-03.jpg" class="takata-img"/>
            </div>

        </div>


        <div class="row">
            <div class="col-12 col-md-12 col-xl-12 takata-media desktop">
                <img src="../../../assets/images/campañas/takata/takata1.webp" class="takata-img"/>
            </div>
        </div>
        <div class="row mobile">
            <div class="col-12 col-md-10 col-xl-8 takata-media">

                <img src="../../../assets/images/campañas/takata/VIN_mobile1.webp"  class="takata-img"/>
            </div>
            <div class="col-12 col-md-10 col-xl-8 takata-media">
                <img src="../../../assets/images/campañas/takata/VIN_mobile2.webp"  class="takata-img"/>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-4 col-xl-4 takata-media">
                <div class="takata-relevant">
                    ¿TIENES ALGUNA DUDA?
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 col-xl-6 takata-media">
                        <a href="https://api.whatsapp.com/send?phone=+59169831219&text=Toyosa%20campaña%20Takata:%20Me%20gustaría%20saber%20más%20sobre%20la%20campaña." target="_blank">
                            <img src="../../../assets/images/campañas/boton_chat.png" class="takata-img"/>
                        </a>
                    </div>
                </div>
                <div class="takata-relevant">
                    o comunícate al
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 col-xl-6 takata-media">
                        <a href="tel:591800175200" target="_blank">
                            <img src="../../../assets/images/campañas/icono_contact_center.png" class="takata-img"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <h2 class="takata-title" style="margin-top: 40px; margin-bottom: 20px;">Preguntas frecuentes</h2>

            <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one" class="takata-accordion mobile" style="margin-bottom: 40px;">
                <ngb-panel title="¿Cuál es el objetivo de esta campaña?" id="config-panel-one">
                    <ng-template ngbPanelContent>
                        <p>
                            Toyota Motors Corporation con sus marcas Toyota & Lexus y la Concesionaria Toyosa tienen por misión velar por la seguridad de sus usuarios, es por esto que la marca dentro de sus pruebas de calidad
                            a detectado una falencia en uno de sus componentes del sistema de Bolsas de Aires (Airbags) de un fabricante externo de piezas llamada TAKATA, para corregir esta falla y las bolsas de aires tengan un correcto funcionamiento,
                             la marca inspeccionará las piezas y de ser necesario las reemplazará, para esto realizará una gran convocatoria a todos los clientes Toyota dueños de las unidades afectadas.
                        </p>
                        <p>
                            Esta campaña está basada en la responsabilidad social que tenemos con el cliente Toyota, se requiere captar la mayor cantidad de personas que podamos para comunicar cuyas unidades puedan estar comprometidas con el correcto funcionamiento de su sistema de Bolsas de Aire (Airbag).
                        </p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Qué es el número de Chasis y cómo puedo encontrarlo en mi vehículo?">
                    <ng-template ngbPanelContent>
                        <p>El VIN es el número de serie vehicular (Vehicle Identification Number) que consta de 17 números y sirve para dar información acerca de la procedencia y características únicas de tu vehículo, el número de chasis son los últimos 8 números del VIN contando de izquierda a derecha. y se puede localizar en diferentes partes del vehículo, así como en algunos documentos oficiales. (Para mayor detalle, favor de referirse vea los gráficos a continuación.)</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Cuál es la condición?">
                    <ng-template ngbPanelContent>
                        <p>Los vehículos involucrados están equipados con un inflador de bolsa de aire frontal, producido por TAKATA. Ese inflador de la bolsa de aire contiene un propulsor que podría degradarse después de ser expuesto a climas con alta humedad y variación de temperaturas. Un propulsor degradado podría ocasionar la ruptura del inflador durante la activación de la bolsa de aire. En el remoto caso de la ruptura del inflador, fragmentos de metal podrían atravesar el material de la bolsa de aire y golpear a los ocupantes, resultando en lesiones serias o la muerte.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Por qué TAKATA está retirando todos los infladores de bolsas de aire involucrados?">
                    <ng-template ngbPanelContent>
                        <p>Múltiples investigaciones y expertos independientes concluyeron que el propulsor de nitrato de amonio en los infladores de bolsas de aire frontales no desecado TAKATA, se degrada con el tiempo.</p>
                        <p>La degradación es resultado de varios factores que existen en la zona o ubicación en la que el vehículo que contiene los infladores es operado principalmente, incluyendo exposición a largo plazo a la humedad ambiental y fluctuaciones de temperatura.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Existe alguna preocupación con otras bolsas de aire en el vehículo?">
                    <ng-template ngbPanelContent>
                        <p>No. El llamado a revisión sólo aplica a ciertos infladores de bolsa de aire, del lado del pasajero o del lado del conductor, en ciertos vehículos Toyota hasta algunos modelos 2016.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Hay alguna señal de advertencia sobre esta condición?">
                    <ng-template ngbPanelContent>
                        <p>No, no se tiene una señal de advertencia específica cuando esta condición ocurre.</p>
                        <p>Sin embargo, la condición no causa que la bolsa de aire frontal del lado del pasajero se active cuando ésta no debe. Adicionalmente, la bolsa de aire del pasajero no está diseñada para desplegarse a menos que haya un ocupante en ese asiento (aunque algunos objetos también podrían causar que el estatus del sistema esté listo para su activación).</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Hay alguna acción que yo pueda hacer para minimizar la aparición de esta situación?">
                    <ng-template ngbPanelContent>
                        <p>No, por eso reiteramos la importancia de revisar el VIN en www.toyosa.com/takata y llevar el vehículo a la agencia Toyosa de tu conveniencia. Adicionalmente, la condición no hace que las bolsas de aire se activen cuando no deberían hacerlo.</p>
                        <p>También hay que considerar que la bolsa de aire del copiloto está diseñada para inflarse sólo en ciertos accidentes moderados a graves y sólo cuando una persona ocupa el asiento del pasajero delantero.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Qué es lo que se debe hacer en caso de tener alguna inquietud?">
                    <ng-template ngbPanelContent>
                        <p>Los Concesionarios Toyota tendrán la atención de responder cualquier pregunta y establecer una cita para llevar a cabo esta revisión.</p>
                        <p>En caso de preguntas o dudas adicionales pueden ponerse en contacto con la línea del Contact Center: <a href="tel:591800175200" class="link-social">800 17 5200</a></p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Actualmente Toyota está manufacturando vehículos con infladores de aire TAKATA?">
                    <ng-template ngbPanelContent>
                        <p>No, ya no se fabrican vehículos con estos infladores.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿En qué consiste la reparación?">
                    <ng-template ngbPanelContent>
                        <p>Si tu vehículo califica para un llamado a revisión, tus bolsas de aire defectuosas, serán reemplazadas SIN COSTO ALGUNO.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Cuánto tiempo toma la reparación?">
                    <ng-template ngbPanelContent>
                        <p>La reparación es relativamente rápida y, dependiendo del modelo, puede variar entre los 45 minutos y 2 horas. Para asegurar la mayor efectividad en tiempo, sugerimos hacer una cita por medio de uno de nuestros canales de contacto (App, Web o llamando al Contact Center: <a href="tel:591800175200" class="link-social">800 17 5200</a></p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Quién es TAKATA?">
                    <ng-template ngbPanelContent>
                        <p>TAKATA era una empresa de autopartes con instalaciones de producción en cuatro continentes. A raíz de una serie de accidentes en varias partes del mundo, en junio de 2014 se generó un comunicado global para dar a conocer la condición del inflador defectuoso en las bolsas de aire del piloto y copiloto y retirar los más de 40 millones de vehículos, de las 19 marcas automotrices, afectados por esto.</p>
                        <p>En junio de 2017, TAKATA se declaró en bancarrota y fue adquirido por la empresa Key Safety Systems, después de lo cual cambiaron su nombre a Joyson Safety Systems.</p>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one" class="takata-accordion desktop" style="margin-bottom: 40px;">
                <ngb-panel title="¿Qué es el número de Chasis y cómo puedo encontrarlo en mi vehículo?" id="config-panel-one">
                    <ng-template ngbPanelContent>
                        <p>El VIN es el número de serie vehicular (Vehicle Identification Number) que consta de 17 números y sirve para dar información acerca de la procedencia y características únicas de tu vehículo, el número de chasis son los últimos 8 números del VIN contando de izquierda a derecha. y se puede localizar en diferentes partes del vehículo, así como en algunos documentos oficiales. (Para mayor detalle, favor de referirse vea los gráficos a continuación.)</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Cuál es la condición?">
                    <ng-template ngbPanelContent>
                        <p>Los vehículos involucrados están equipados con un inflador de bolsa de aire frontal, producido por TAKATA. Ese inflador de la bolsa de aire contiene un propulsor que podría degradarse después de ser expuesto a climas con alta humedad y variación de temperaturas. Un propulsor degradado podría ocasionar la ruptura del inflador durante la activación de la bolsa de aire. En el remoto caso de la ruptura del inflador, fragmentos de metal podrían atravesar el material de la bolsa de aire y golpear a los ocupantes, resultando en lesiones serias o la muerte.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Por qué TAKATA está retirando todos los infladores de bolsas de aire involucrados?">
                    <ng-template ngbPanelContent>
                        <p>Múltiples investigaciones y expertos independientes concluyeron que el propulsor de nitrato de amonio en los infladores de bolsas de aire frontales no desecado TAKATA, se degrada con el tiempo.</p>
                        <p>La degradación es resultado de varios factores que existen en la zona o ubicación en la que el vehículo que contiene los infladores es operado principalmente, incluyendo exposición a largo plazo a la humedad ambiental y fluctuaciones de temperatura.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Existe alguna preocupación con otras bolsas de aire en el vehículo?">
                    <ng-template ngbPanelContent>
                        <p>No. El llamado a revisión sólo aplica a ciertos infladores de bolsa de aire, del lado del pasajero o del lado del conductor, en ciertos vehículos Toyota hasta algunos modelos 2016.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Hay alguna señal de advertencia sobre esta condición?">
                    <ng-template ngbPanelContent>
                        <p>No, no se tiene una señal de advertencia específica cuando esta condición ocurre.</p>
                        <p>Sin embargo, la condición no causa que la bolsa de aire frontal del lado del pasajero se active cuando ésta no debe. Adicionalmente, la bolsa de aire del pasajero no está diseñada para desplegarse a menos que haya un ocupante en ese asiento (aunque algunos objetos también podrían causar que el estatus del sistema esté listo para su activación).</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Hay alguna acción que yo pueda hacer para minimizar la aparición de esta situación?">
                    <ng-template ngbPanelContent>
                        <p>No, por eso reiteramos la importancia de revisar el VIN en www.toyosa.com/takata y llevar el vehículo a la agencia Toyosa de tu conveniencia. Adicionalmente, la condición no hace que las bolsas de aire se activen cuando no deberían hacerlo.</p>
                        <p>También hay que considerar que la bolsa de aire del copiloto está diseñada para inflarse sólo en ciertos accidentes moderados a graves y sólo cuando una persona ocupa el asiento del pasajero delantero.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Qué es lo que se debe hacer en caso de tener alguna inquietud?">
                    <ng-template ngbPanelContent>
                        <p>Los Concesionarios Toyota tendrán la atención de responder cualquier pregunta y establecer una cita para llevar a cabo esta revisión.</p>
                        <p>En caso de preguntas o dudas adicionales pueden ponerse en contacto con la línea del Contact Center: <a href="tel:591800175200" class="link-social">800 17 5200</a></p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Actualmente Toyota está manufacturando vehículos con infladores de aire TAKATA?">
                    <ng-template ngbPanelContent>
                        <p>No, ya no se fabrican vehículos con estos infladores.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿En qué consiste la reparación?">
                    <ng-template ngbPanelContent>
                        <p>Si tu vehículo califica para un llamado a revisión, tus bolsas de aire defectuosas, serán reemplazadas SIN COSTO ALGUNO.</p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Cuánto tiempo toma la reparación?">
                    <ng-template ngbPanelContent>
                        <p>La reparación es relativamente rápida y, dependiendo del modelo, puede variar entre los 45 minutos y 2 horas. Para asegurar la mayor efectividad en tiempo, sugerimos hacer una cita por medio de uno de nuestros canales de contacto (App, Web o llamando al Contact Center: <a href="tel:591800175200" class="link-social">800 17 5200</a></p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel title="¿Quién es TAKATA?">
                    <ng-template ngbPanelContent>
                        <p>TAKATA era una empresa de autopartes con instalaciones de producción en cuatro continentes. A raíz de una serie de accidentes en varias partes del mundo, en junio de 2014 se generó un comunicado global para dar a conocer la condición del inflador defectuoso en las bolsas de aire del piloto y copiloto y retirar los más de 40 millones de vehículos, de las 19 marcas automotrices, afectados por esto.</p>
                        <p>En junio de 2017, TAKATA se declaró en bancarrota y fue adquirido por la empresa Key Safety Systems, después de lo cual cambiaron su nombre a Joyson Safety Systems.</p>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
</div>

<ng-template #contentencontrado let-c="close" let-d="dismiss" class="modal-pago">
    <div class="modal-body modal-confirm">
        <div class="modal-contenido">
            <h2 class="title-popup">Habilitado para campaña Takata</h2>
            <div class="recoger">
                Estimad@ cliente, su vehículo se encuentra habilitado para la campaña takata, por favor contáctenos para agendar su cita en nuestros talleres
                <!--<span *ngIf="isLogin">Por favor agende su cita en nuestro taller para acceder a la campaña</span>
                <span *ngIf="!isLogin">Por favor inicie sesión en la plataforma para agendar su cita</span>-->
            </div>
            <a href="https://api.whatsapp.com/send?phone=+59169831219&amp;text=Hola,%20tengo%20una%20consulta%20sobre%20takata" class="wp-takata">
                <img src="../../../assets/icons/btn_whatsapp.svg" class="btn-whatsapp"/>
            </a>
            <!--<div *ngIf="isLogin">
                <a class="btn-redirect" (click)="clickUrl('/servicio-tecnico')">Agendar cita</a>
            </div>
            <div *ngIf="!isLogin">
                <a class="btn-redirect" (click)="clickUrl('/login')">Iniciar sesión</a>
                <div class="divider">
                    <div class="divider-left"></div>
                    <span class="diviter-text">
                      Ó
                    </span>
                    <div class="divider-right"></div>
                </div>
                <div>
                    ¿No tiene una cuenta? registrese <a (click)="clickUrl('/usuario/registro')" class="btn-send">aquí</a>
                </div>
            </div>-->
        </div>
    </div>
</ng-template>

<ng-template #contentnoencontrado let-c="close" let-d="dismiss" class="modal-pago">
    <div class="modal-body modal-confirm">
        <div class="modal-contenido">
            <h2 class="title-popup-nohabilitado">No habilitado para la campaña</h2>
            <div class="recoger">
                Estimad@ cliente, su vehículo no se encuentra habilitado para la campaña takata.
                <span *ngIf="isLogin">Navegue en nuestra plataforma para:</span>
                <span *ngIf="!isLogin">Recomendamos se registre en la plataforma para que pueda realizar su agendado de cita para servicio técnico o la compra de sus repuestos.</span>
            </div>
            <div *ngIf="isLogin">
                <a class="btn-redirect">Agendar cita</a>
                <a class="btn-redirect">Ver repuestos</a>
            </div>
            <div *ngIf="!isLogin">
                <a class="btn-redirect">Registrarme</a>
            </div>
        </div>
    </div>
</ng-template>
<simple-notifications></simple-notifications>
