import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogoService {
  token: string | null;

    constructor(private http: HttpClient) { 
      const userLocal = localStorage.getItem('userPlataformaTY');
      if (userLocal) {
        this.token = JSON.parse(userLocal).token;
      } else {
        this.token = "No exite token";
      }
    }
    // Obtener filtros de una marca
    getEmpresasB2B() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/usuario/empresas`;
      return this.http.get<any>(url, httpOptions);
    }
    // Servicio que envia el formulario, recibe un objeto con la información de los campos
    enviarFormulario(datos: any) {
      const url = `${environment.base_url}/contactenos`;
      return this.http.post<any>(url, datos);
    }
    // Obtener filtros de una marca
    getArticulos( per_page: number, page: number, codcategoria:number) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
      };
      const url = `${environment.base_url_v2}/repuesto/marca/todas?per_page=${per_page}&page=${page}&categoria_articulo=${codcategoria}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Obtener filtros de una marca
    getRepuestos( per_page: number, page: number) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url_v2}/repuesto/marca/todas?per_page=${per_page}&page=${page}&categoria_articulo=1`;
      return this.http.get<any>(url, httpOptions);
    }

    // Obtener filtros de una marca
    getRepuestosMarca(marca:any, per_page: number, page: number) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
      };
      const url = `${environment.base_url_v2}/repuesto/marca/${marca}?per_page=${per_page}&page=${page}&categoria_articulo=1`;
      return this.http.get<any>(url, httpOptions);
    }

    // Obtener filtros de una marca
    getRepuestosPromocion( per_page: number, page: number) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
      };
      const url = `${environment.base_url}/repuesto/marca/todas?per_page=${per_page}&page=${page}&oferta=1`;
      return this.http.get<any>(url, httpOptions);
    }

    // Obtener filtros de una marca
    getRepuestosPromociones( per_page: number, page: number, idprom:number) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
      };
      const url = `${environment.base_url}/repuesto/marca/todas?per_page=${per_page}&page=${page}&oferta=${idprom}`;
      return this.http.get<any>(url, httpOptions);
    }
    // Obtener filtros de una marca
    getRepuestosCompletos() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/repuesto/marca/todas`;
      return this.http.get<any>(url, httpOptions);
    }

    // Obtener informacion de los filtros
    getRepuestoInformacion(marca: any, id: any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      //const url = `${environment.base_url}/repuesto/${marca}/${id}`;
      const url = `${environment.base_url}/repuesto/${id}`;
      return this.http.get<any>(url, httpOptions);
    }

    // Obtener llantas
    getLlantas( per_page: number, page: number) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
      };
      const url = `${environment.base_url_v2}/repuesto/marca/todas?per_page=${per_page}&page=${page}&categoria_articulo=2`;
      return this.http.get<any>(url, httpOptions);
    }
    // Obtener llantas
    getLlantasMarca( marca:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
      };
      const url = `${environment.base_url}/llanta/filtrado?id_marca_repuesto=${marca}`;
      return this.http.get<any>(url, httpOptions);
    }

    // BUSCXADOR
    getBusquedaRepuestos(termino:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
      };
      const url = `${environment.base_url}/repuesto/q?search=${termino}`;
      return this.http.get<any>(url, httpOptions);
    }

    // BUSCXADOR DE VEHICULOS
    getBusquedaVehiculos(termino:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        })
      };
      const url = `${environment.base_url}/repuesto/q?source=2&search=${termino}`;
      return this.http.get<any>(url, httpOptions);
    }

    /*Despliegue de sucursales*/
    getCiudadesTiendas() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/sucursal/departamento`;
      return this.http.get<any>(url, httpOptions)
    }

    postCiudadPedido(datos:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/usuario/preferencia`;
      return this.http.post<any>(url, datos, httpOptions);
    }

    /*Despliegue de sucursales*/
    getPinesCiudad(idCiudad:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/sucursal/departamento/${idCiudad}`;
      return this.http.get<any>(url, httpOptions)
    }

    /*CARRITO DE COMPRAS */
    /*Despliegue de sucursales*/
    verCarrito() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/compra/producto`;
      return this.http.get<any>(url, httpOptions)
    }

    verTransacciones() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/compra/pedido`;
      return this.http.get<any>(url, httpOptions)
    }

    agregarCarrito(datos: any): Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/compra/producto`;
      return this.http.post<any>(url, datos, httpOptions);
    }

    eliminarItemCarrito(id: string){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/compra/producto/${id}`;
      return this.http.delete(url, httpOptions);
    }

    actualizarCantidadCarrito(data:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/compra/producto`;
      return this.http.post<any>(url, data, httpOptions);
    }

    /* Filtros de llantas */
    listadoDeAro() {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/llanta/filtrado`;
      return this.http.get<any>(url, httpOptions)
    }

    listadoDimensiones(aro:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/llanta/filtrado?aro=${aro}`;
      return this.http.get<any>(url, httpOptions)
    }

    listadoMarcaDisponible(aro:any, medidas:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/llanta/filtrado?aro=${aro}&medida=${medidas}`;
      return this.http.get<any>(url, httpOptions)
    }

    getBusquedallantas(aro:any, medidas:any, marca:any) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.token}`
        })
      };
      const url = `${environment.base_url}/llanta/filtrado?aro=${aro}&medida=${medidas}&id_marca_repuesto=${marca}`;
      return this.http.get<any>(url, httpOptions)
    }
}
