<div class="desktop">
  <div id='header' class="no-mobile">
      <div id="header-menu-desktop"  class="position-fixed menu-toyosa" [ngClass]="{'scrolled': isScrolled}">
          <div class="container" >
              <div class="navbar-expand-lg navbar-light d-inline-flex header-menu">
                  <a class="float-start navbar-brand " routerLink="">
                      <img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559522/production/plataforma-toyota/web/assets/icons/comunications/logo_toyosa_negro.svg" width="145" height="35" alt="Logo toyosa" class="mt-2 logo"/>
                  </a> 
                  <div class="navbar navbar-nav justify-content-center">
                    <div class="input-group mt-2">
                      <input id="typeahead-focus" type="text" class="form-control search-ecommerce"
                          [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)"
                          (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead"
                          placeholder="¿Qué repuesto buscas?" [(ngModel)]="model" (keyup)="onKey($event)"/>
                      <div class="input-group-append">
                        <button class="btn-search" (click)="linkSearch()" type="button"><img src="../../../assets/icons/loupe.svg" class="search-loupe"/></button>
                      </div>
                    </div>
                    <div class="d-flex mt-2">
                        <div class="sucursal mobile"><img src="../../../assets/icons/pin_ubicaion.svg" class="pin-sucursal"/> {{sucursalCarrito}}</div>
                        <a routerLink='/tienda-virtual' class="desktop">E-commerce</a>
                        <div class="vr mx-3"></div>
                        <a routerLink='/tienda-virtual' class="mobile">E-commerce</a>
                        <a routerLink="/servicio-tecnico" class="desktop"> Servicio Técnico</a>
                        <div class="vr mx-3"></div>
                        <a routerLink="/taller-movil" class="desktop">Taller Móvil</a>
                        <a routerLink="/servicio-tecnico" class="mobile"> Servicio Técnico</a>
                        <!--<a routerLink="/ofertas/oferta-para-tu-toyota"><img src="../../../assets/icons/price_tag-01.svg" class="mini-tag"/>Ofertas</a>-->
                    </div>
                  </div>                    
                  <ul class="navbar-nav justify-content-center mt-2" *ngIf="!userJson; else userData">
                      <li ngbDropdown class="nav-item" >
                          <a class="nav-link item-toyota" routerLink="/usuario/registro">Registro</a>                  
                      </li>
                      <div class="vr mx-3"></div>
                      <li ngbDropdown class="nav-item">
                          <a class="nav-link item-toyota" routerLink="/login">Login</a>                  
                      </li>
                  </ul>

                  <ng-template #userData>
                      <ul class="navbar-nav justify-content-center menu-profile mt-2">
                          <li class="nav-item nav-cart">
                            <a routerLink="/tienda-virtual/carrito"><img src="../../assets/icons/carrito.svg" class="carrito-img"/><span class="badge badge-pt">{{cantidadCarrito}}</span></a>
                            <div class="sucursal">{{sucursalCarrito}}</div>
                          </li>
                          <li ngbDropdown class="nav-item ">
                              <a class="d-flex py-2 px-4 rounded-5 item-profile" routerLink="/usuario/perfil">{{nombreUsuario}} <img src="../../assets/icons/profile-user.svg" class="mx-2 img-profile"/></a>                  
                          </li>
                      </ul>
                  </ng-template> 
              </div>
          </div>
          
          
      
      </div>
  </div>
</div>







