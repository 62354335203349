import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbAccordionConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { first } from 'rxjs/operators';
import { CampanasService } from 'src/app/services/campanas.service';

@Component({
  selector: 'app-takata',
  templateUrl: './takata.component.html',
  styleUrls: ['./takata.component.css']
})
export class TakataComponent implements OnInit {
  @ViewChild('contentencontrado') templateEncontrado: TemplateRef<any> | undefined;    
  @ViewChild('contentnoencontrado') TemplateNoEncontrado: TemplateRef<any> | undefined;

  formulario: FormGroup;
  placavehiculo: any;

  isLogin;

  constructor(private taktaservice: CampanasService, config: NgbAccordionConfig, private modalService: NgbModal, private router: Router, private ngZone:NgZone, private notificaciones: NotificationsService, private meta: Meta) {
    // customize default values of accordions used by this component tree
    config.closeOthers = true;
    config.type = 'info';

    this.isLogin = false;
    this.verificandoUsuario();
    
    this.formulario = new FormGroup({
      placa: new FormControl('', Validators.required),
     
    })

    this.meta.updateTag(
      {
        property:'og:title', content:'Llamado a revisión de las bolsas de aire Takata '
      }
    )
    this.meta.updateTag(
      {
        property:'og:description', content:'Si tu Toyota o Lexus fue ensamblado entre los años 2002 y 2015, es posible que tus bolsas de aire tengan un desperfecto y necesitan ser reemplazados inmediatamente.'
      }
    )
    this.meta.updateTag(
      {
        property:'og:image', content:'https://www.toyosa.com/assets/images/campañas/volanre_takata.jpg'
      }
    )
    this.meta.updateTag(  
      { name: 'keywords', content: 'toyota, lexus, toyosa, takata, revisión, bolsas de aire, agendar cita, servicio técnico, ecommerce de repuestos, repuestos toyota bolivia, repuestos lexus bolivia, vehiculos, auto, autos, vagoneta, vagonetas, camioneta, camionetas, buses, repuestos, corolla, corolla hibrido, yaris, fortuner, land cruiser, 4runner, rav4, landcruiser prado, rush, hilux, hilux nf, hilux trd, coaster, hiace, cotizaciones, cotizador, comparador, modelos, santa cruz, la paz, cochabamba, potosi, sucre, chuquisaca, cobija, beni, tarija, trinidad, oruro' } 
    ) 
  }

  ngOnInit(): void {
    
  }

  buscarvehiculo() {
    this.taktaservice.buscarVehiculoTakata(this.placavehiculo).pipe(first()).subscribe(
      data => {
          this.openModal(this.templateEncontrado);
          if(this.isLogin == true) {
            this.guardarCliente(data.vehiculo.n_chasis);
          }
      }, error => {
        this.openModal(this.TemplateNoEncontrado);
      }
    );
  }

  guardarCliente(chasis:any) {
    let userData:any = localStorage.getItem('userPlataformaTY');
    let userJson = JSON.parse(userData);

    let data = {
      chasis:chasis,
      name:userJson.nombre,
      lastName:userJson.apellidos,
      ci:userJson.nro_documento,
      ci_expedicion:userJson.id_departamento,
      phoneNumber:userJson.telefono
    }
    this.taktaservice.registrarClienteTakata(data).pipe(first()).subscribe(
      res => {
        console.log(res);
      }, error => {
        console.log(error)
      }
    )
  }

  openModal(content:any) {
    this.modalService.open(content,{ centered: true, size: 'md' });
  }

  verificandoUsuario() {
    let userData:any = localStorage.getItem('userPlataformaTY');
    let userJson = JSON.parse(userData);

    if(userJson != null){
        this.isLogin = true;
    } else {
        this.isLogin = false;
    }
  }

  clickUrl(url:any) {
    setTimeout(() => {
      this.ngZone.run(()=>this.navigateTo(url , this.placavehiculo))
    }, 100);
  }

  navigateTo(url:any, param:any){
    this.router.navigate([url], { queryParams: { takata: param } }).then(() => {
      window.location.reload();
    });
  
  }
}
