import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { CatalogoService } from 'src/app/services/ecommerce/catalogo.service';

@Component({
  selector: 'app-pedido-envio',
  templateUrl: './pedido-envio.component.html',
  styleUrls: ['./pedido-envio.component.css']
})
export class PedidoEnvioComponent implements OnInit {
  @ViewChild('contentcp') templateRef: TemplateRef<any> | undefined;
  ciudadesTiendas=[];
  formulario: FormGroup;
  dataCompletaBolivia:any=[];
  verCiudad:boolean;
  verMapa=false;

  lat = -17.4911607;
  lng = -63.9826885;
  zoom = 6;

  constructor(private repuestosService: CatalogoService, private modalService: NgbModal, private sucursalesService: CatalogoService) {
    this.verCiudad = false;

    this.repuestosService.getCiudadesTiendas().pipe(first()).subscribe(
      data => {
        this.ciudadesTiendas = data;
      }, error => console.log(error)
    );
    this.formulario = new FormGroup({
      sucursal: new FormControl('', Validators.required)
    })
   }

  ngOnInit(): void {
  }
  viewMap() {
    if (this.verMapa) {
      this.verMapa = false;
    } else{
      this.verMapa = true;
    }
  }

  ngAfterViewInit() {
      this.openModal(this.templateRef);
  }

  openModal(content:any) {
    this.modalService.open(content, { size: 'lg' });
  }

  selectCiudad(event:any){
    const ciudad:any = event.target.value;
    let literal = "";
    for (let ic = 0; ic < this.ciudadesTiendas.length; ic++) {
      if(this.ciudadesTiendas[ic]['id_departamento'] == ciudad){
        literal = ""+this.ciudadesTiendas[ic]['nombre_departamento'];
      }
    }
    
    localStorage.setItem('ciudadECPT', JSON.stringify(ciudad));
    localStorage.setItem('ciudadLiteralECPT', JSON.stringify(literal));
    let userData:any = localStorage.getItem('userPlataformaTY');
    let userJson = JSON.parse(userData);

    if(userJson != null){
      let datos = {
        _method:'PUT',
        id_ciudad:ciudad
      };
      this.repuestosService.postCiudadPedido(datos).subscribe( data => {
  
      }, error => {
          console.log(error);
      });
    } else {
    }
    
    
    this.verificarSucursales();
  }

  verificarSucursales(){
    let ciudadData:any = localStorage.getItem('ciudadECPT');
    let ciudadJson = JSON.parse(ciudadData);

    if(ciudadJson != null){
      this.verCiudad = false;
      this.sucursalesService.getPinesCiudad(ciudadJson).pipe(first()).subscribe(
        data => {
          this.dataCompletaBolivia = data;
        
        }, error => {
        }
      ); 
    } else {
      this.verCiudad = true;
      this.sucursalesService.getCiudadesTiendas().pipe(first()).subscribe(
        data => {
          this.ciudadesTiendas = data;
          this.openModal(this.templateRef);
        }, error => console.log(error)
      );
    }
  }

  activarSucursal(idsucursal: any) {
    console.log(idsucursal);
    this.formulario.controls.sucursal.setValue(idsucursal['id_sucursal']);
    
  }

  guardar() {
    this.modalService.dismissAll();
    localStorage.setItem('sucursalpedidoPT', JSON.stringify(this.formulario.controls.sucursal.value));
  }
}
