<div class="input-group">
        <input id="typeahead-focus" type="text" class="form-control search-ecommerce"
            [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)"
            (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead"
            placeholder="¿Qué repuesto buscas?" [(ngModel)]="model" (keyup)="onKey($event)"/>
        <div class="input-group-append">
          <button class="btn-search" (click)="linkSearch()" type="button"><img src="../../../assets/icons/loupe.svg" class="search-loupe"/></button>
        </div>
</div>
<div class="bottom-menu">
    <div class="sucursal mobile"><img src="../../../assets/icons/pin_ubicaion.svg" class="pin-sucursal"/> {{sucursalCarrito}}</div>
    <a routerLink='/tienda-virtual' class="desktop">E-commerce</a>
    <span class="separator">|</span>
    <a routerLink='/tienda-virtual' class="mobile">E-commerce</a>
    <a routerLink="/servicio-tecnico" class="desktop"> Servicio Técnico</a>
    <span class="separator">|</span>
    <a routerLink="/taller-movil" class="desktop">Taller Móvil</a>
    <a routerLink="/servicio-tecnico" class="mobile"> Servicio Técnico</a>
    <!--<a routerLink="/ofertas/oferta-para-tu-toyota"><img src="../../../assets/icons/price_tag-01.svg" class="mini-tag"/>Ofertas</a>-->
</div>
