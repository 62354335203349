import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { CatalogoService } from 'src/app/services/ecommerce/catalogo.service';

@Component({
  selector: 'app-menu-b2b',
  templateUrl: './menu-b2b.component.html',
  styleUrls: ['./menu-b2b.component.css']
})
export class MenuB2bComponent implements OnInit {
  public model: any;
   @ViewChild('instance', { static: true })
   instance!: NgbTypeahead;
   focus$ = new Subject<string>();
   click$ = new Subject<string>();
   states:any = [];
   
   topHeader = 0;
 
   /* info del usuario*/
   public userData: any;
   public userJson:any;
   nombreUsuario:string="";
   cantidadCarrito:any;
   sucursalCarrito:any;
   web = true;
   activarCiudad:any;
   ciudadPedido:any;
   isScrolled = false;

   @Input() usuario:any;
   @Input() carrito:any;
   @Input() carritociudad:any;
   @Input() carritosucursal:any;
        
   @HostListener('window:scroll', [])
     onWindowScroll() {
       const offset = window.pageYOffset;
       this.isScrolled = offset > 50; // Cambia el valor según lo necesites
     }
   
 
   constructor(private repuestosService: CatalogoService, private router: Router) { 
     this.verificandoUsuario();
 
   }
 
   ngOnInit(): void {
 
     this.router.events.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
       this.verificandoUsuario();
       
     });
    
   }
 
   verificandoUsuario() {
     localStorage.removeItem("toyocarrito");
     this.userData = localStorage.getItem('userPlataformaTY');
     this.userJson = JSON.parse(this.userData);
 
     if(this.userJson != null){
         this.nombreUsuario = "Hola, "+(this.userJson['nombre'].split(' ')[0]);
         this.repuestosService.verCarrito().pipe(first()).subscribe(
           data => {
             //console.log(data);
             if(data.detalle_productos) {
               if(data.detalle_productos.length > 0) {
                 localStorage.setItem('toyocarrito', JSON.stringify(data));
                 this.cantidadCarrito = data.detalle_productos.length;
                 this.sucursalCarrito = data.detalle_productos[0].nombre_sucursal;
               } else {
                 //this.cantidadCarrito = 0;
               }
             }
             
           }, error => console.log(error)
         );
     } else {
       this.nombreUsuario = "Toyosa";
     }
   }
 
   search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
     const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
     const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
     const inputFocus$ = this.focus$;
     /*this.repuestosService.getRepuestosCompletos().subscribe( data => {
       for (let searchData = 0; searchData < data.datos.length; searchData++) {
         this.states.push(data.datos[searchData].nombre_repuesto)
       }
       //console.log(this.states);
     }, error => {
         console.log(error);
     });*/
     return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
       map(term => (term === '' ? this.states
         : this.states.filter((v: string) => v.indexOf(term) > -1)).slice(0, 10))
     );
   }
 
   linkSearch() {
       this.router.navigate(['/tienda-virtual/busqueda/'+this.model]);
       //console.log(this.model);
   }
 
   onKey(e:any) {
     let searchKaizen = {};
     if(this.model.length > 2) {
       if(e.key == "Enter"){
           //console.log("Ingresa en enter: " +this.searchDesktop);
           this.router.navigate(['/tienda-virtual/busqueda/'+this.model]);
       }
     }
   }
 
 }
 