<div class="vehiculos-radio">
    <div class="container">
        <div class="row">
            <div class="col-0 col-md-3 col-xl-3">
                <a routerLink="/servicio-tecnico">
                    <img src="../../assets/images/radios/servicio_tecnico.svg"/>
                </a>
            </div>
            <div class="col-0 col-md-3 col-xl-3">
                <a routerLink="/taller-movil">
                    <img src="../../assets/images/radios/taller_movil.svg"/>
                </a>
            </div>
            <div class="col-0 col-md-3 col-xl-3">
                <a routerLink="/tienda-virtual">
                    <img src="../../assets/images/radios/e-commerce.svg"/>
                </a>
            </div>
            <div class="col-0 col-md-3 col-xl-3">
                <a href="tel:591800175200" target="_blank">
                    <img src="../../assets/images/radios/contact_center.svg"/>
                </a>
            </div>
        </div>
    </div>
</div>
<div>
    <img src="../../assets/images/radios/banner_taller_movil.webp" class="banner-inferior"/>
</div>
