import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import * as https from "https";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient) { }

  // Servicio que obtiene la lista de todos vehiculos
  getAutomovilesAll(marcaSeleccionada:string | null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/marca/${marcaSeleccionada}`;
    return this.http.get<any>(url, httpOptions);
  }


  // Servicio que obtiene los datos de cada vehiculo
  getAutomoviles(modeloKey: string | null) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    // const url = `${environment.base_url}/${marcaSeleccionada}/automoviles/subcategorias/${modeloKey}/caracteristicas`;
    const url = `${environment.base_url}/modelo/${modeloKey}`;
    return this.http.get<any>(url, httpOptions);
  }

  // Servicio que obtiene la lista de todos vehiculos
  getAutomovilesAllV1() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    //agregar v1
    const url = `${environment.base_url}/toyota/automoviles/all`;
    return this.http.get<any>(url, httpOptions);
  }

  // Servicio que obtiene los datos de cada vehiculo
  comparador(idModelo: number): Observable<HttpClient> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${environment.base_url}/vehiculo/comparador/${idModelo}`;
    return this.http.get<any>(url, httpOptions);
  }

}
