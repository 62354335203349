<div class="rrss">
    <div class="container-toyowidget">
        <div class="capsule">
            <div class="rrss-nm">WhatsApp</div>
            <a class="toyowidget wp" href="https://api.whatsapp.com/send?phone=+59169831219&text=Hola,%20estaba%20en%20la%20página%20web%20y%20tengo%20una%20consulta" target="_blank">
                <img [src]="imgwhatsapp" width="40" height="40" alt="Icono de whatsapp" class="widget-wp"/>
            </a>
        </div>
    </div>
    <div class="container-toyowidget contenedores" id="toyoopen" >
        <div class="capsule">
            <a class="toyowidget_toyosa ty" [href]="linkmsn2" target="_blank">
                <img [src]="imagefacebook" width="50" height="50" alt="Icono de live chat" class="widget-wp"/>
                <!--<span>Chat</span>-->
            </a>
        </div>
    </div>
</div>