import { Component, OnInit, ViewChild } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { CatalogoService } from 'src/app/services/ecommerce/catalogo.service';

@Component({
  selector: 'app-menu-ecommerce',
  templateUrl: './menu-ecommerce.component.html',
  styleUrls: ['./menu-ecommerce.component.css']
})
export class MenuEcommerceComponent implements OnInit {
  public model: any;
  @ViewChild('instance', { static: true })
  instance!: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  states:any = [];
  
  topHeader = 0;

  /* info del usuario*/
  public userData: any;
  public userJson:any;
  nombreUsuario:string="";
  sucursalCarrito:any;

  constructor(private repuestosService: CatalogoService, private router: Router) { }

  ngOnInit(): void {

    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        
      }

      if (event instanceof NavigationEnd) {
        this.verificandoUsuario();
      }

      if (event instanceof NavigationError) {
      }
    });
   
  }

  verificandoUsuario() {
    this.userData = localStorage.getItem('userPlataformaTY');
    this.userJson = JSON.parse(this.userData);

    if(this.userJson != null){
        this.nombreUsuario = "Hola, "+(this.userJson['nombre'].split(' ')[0]);
        this.repuestosService.verCarrito().pipe(first()).subscribe(
          data => {
            //console.log(data);
            if(data) {
              this.sucursalCarrito = data.detalle_productos[0].nombre_sucursal;
            } else {
              //this.cantidadCarrito = 0;
            }
          }, error => console.log(error)
        );
    } else {
      this.nombreUsuario = "Toyosa";
    }
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
    /*this.repuestosService.getRepuestosCompletos().subscribe( data => {
      for (let searchData = 0; searchData < data.datos.length; searchData++) {
        this.states.push(data.datos[searchData].nombre_repuesto)
      }
      //console.log(this.states);
    }, error => {
        console.log(error);
    });*/
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.states
        : this.states.filter((v: string) => v.indexOf(term) > -1)).slice(0, 10))
    );
  }

  linkSearch() {
      this.router.navigate(['/tienda-virtual/busqueda/'+this.model]);
      //console.log(this.model);
  }

  onKey(e:any) {
    let searchKaizen = {};
    if(this.model.length > 2) {
      if(e.key == "Enter"){
          //console.log("Ingresa en enter: " +this.searchDesktop);
          this.router.navigate(['/tienda-virtual/busqueda/'+this.model]);
      }
    }
  }

}
