import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CampanasService {
  urlProd = 'https://ws-toyosa.toyosaapis.com/api/v1'
  
  constructor(private http: HttpClient) { }

  // Servicio que busca los vehículos de la campaña takata por placa o chasis
  buscarVehiculoTakata(placa:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${this.urlProd}/campania/takata/vehiculo?search=${placa}`;
    return this.http.get<any>(url, httpOptions)
  }

  // Servicio que busca los vehículos de la campaña takata por placa o chasis
  registrarClienteTakata(data:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const url = `${this.urlProd}/campania/cliente`;
    return this.http.post<any>(url, data, httpOptions)
  }
}
