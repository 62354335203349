<div class="">
    <div id='header' class="header_mobile">
        <div class="menu-fixed" *ngIf="web">
            <div class="container header-menu_mobile" *ngIf="!viewModelsBlock && !search">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <a class="navbar-brand" routerLink="">
                        <img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559522/production/plataforma-toyota/web/assets/icons/comunications/logo_toyosa_negro.svg" width="20" height="18" alt="Logo toyosa" class="logo"/>
                    </a> 
                    <!--<a class="nav-link item-toyota nav-ofertas"  routerLink="/ofertas/filtros-de-combustible" (click)="noViewMenuPrincipal()" *ngIf="!mostrareCommerce"><img src="../../../assets/icons/price_tag-01.svg" class="mini-tag"/> Ofertas</a>-->
                    <div class="ecommerce-cart mt-2" *ngIf="mostrareCommerce">
                        <div class="nav-cart">
                            <a routerLink="/tienda-virtual/carrito"><img src="../../assets/icons/carrito.svg" width="35" height="32"class="carrito-img"/><span class="badge badge-pt">{{cantidadCarrito}}</span></a>
                        </div>
                    </div>     
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="viewMenuPrincipal()">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </nav>
            </div>
            <div class="row nav-search" *ngIf="mostrareCommerce">
                <app-menu-ecommerce  class="col-11 control-ecommerce"></app-menu-ecommerce>
            </div>
            <div class="row nav-search" *ngIf="mostrarCatalogo">
                <app-menu-catalogo  class="col-11 control-ecommerce"></app-menu-catalogo>
            </div>
            
            <div class="sub-menu_links" *ngIf="!mostrareCommerce">
                <a routerLink="/tienda-virtual" class="sub-menu_links__ref"><!--<img src="../../../assets/icons/price_tag-01.svg" class="mini-tag"/> -->Repuestos</a>
                <span class="separator">|</span>
                <a routerLink="/servicio-tecnico" class="sub-menu_links__ref"><!--<img src="../../../assets/icons/price_tag-01.svg" class="mini-tag"/> -->Servicio Técnico</a>
                <span class="separator">|</span>
                <a routerLink="/taller-movil" class="sub-menu_links__ref">Taller Móvil</a>
            </div>
            
            <div class="menu-principal" *ngIf="menuPrincipal && !search">
                <div class="row">
                    <div class="col-8 menu-principal_items">
                        <div class="row pt-4 pb-4 brand-principal">
                            <a class="col-6 pl-5 navbar-brand_principal" routerLink="">
                                <img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559522/production/plataforma-toyota/web/assets/icons/comunications/logo_toyosa_negro.svg" alt="Logo toyosa" class="logo_principal"/>
                            </a> 
                            <div class="col-6">
    
                            </div>
                        </div>
                        <ng-template #userData>
                            <div class="wellcome-user">
                                <a class="nav-link item-profile" id="user" routerLink="/usuario/perfil" (click)="noViewMenuPrincipal()"><img src="../../assets/icons/profile-user.svg" class="img-profile"/>{{nombreUsuario}} </a>                  
                            </div>
                        </ng-template>
                        <div class="wellcome-user" *ngIf="!userJson">
                            <div class="nav-link item-profile">Bienvenido(a) </div>                  
                        </div>
                        <ul class="nav flex-column " *ngIf="!userJson; else userData">
                            <li class="nav-item">
                                <a class="nav-link item-toyota"  routerLink="/usuario/registro" (click)="noViewMenuPrincipal()">Registro</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link item-toyota"  routerLink="/login" (click)="noViewMenuPrincipal()">Login</a>
                            </li>
                        </ul>
                        <div class="row separador-menu" *ngIf="!userJson">
                        </div>
                        <ul class="nav flex-column ">
                            
                            <li class="nav-item">
                                <a class="nav-link item-toyota" (click)="isCollapsedPostVenta = !isCollapsedPostVenta" [attr.aria-expanded]="!isCollapsedPostVenta" aria-controls="collapsePostVenta">
                                    Vehículos nuevos
                                </a>
                                <div id="collapsePostVenta" [ngbCollapse]="!isCollapsedPostVenta">
                                    <ul class="nav flex-column pl-3 submenu-toyota">
                                        <li class="nav-item sub-item_toyota"><a class="nav-link" href="https://www.toyota.bo/" target="_blank" (click)="noViewMenuPrincipal()">Toyota  <img src="../../assets/icons/external-link-black.svg" class="icono-link-externo"/></a></li>
                                        <li class="nav-item sub-item_toyota"><a class="nav-link" href="https://www.lexus.com.bo/es.html" target="_blank" (click)="noViewMenuPrincipal()">Lexus  <img src="../../assets/icons/external-link-black.svg" class="icono-link-externo"/></a></li>
                                        <!--<li class="nav-item sub-item_toyota"><a class="nav-link" routerLink="/seminuevos"  (click)="noViewMenuPrincipal()">Seminuevos</a></li>-->
                                    </ul>
                                </div>
                            </li>
                            <!--<li class="nav-item">
                                <a class="nav-link item-toyota"  href="https://www.seminuevos.toyota.bo/" target="_blank" (click)="noViewMenuPrincipal()">Vehículos seminuevos  <img src="../../assets/icons/external-link-black.svg" class="icono-link-externo"/></a>
                            </li>-->
                            <li class="nav-item">
                                <a class="nav-link item-toyota"  routerLink="/servicio-tecnico" (click)="noViewMenuPrincipal()"><!--<img src="../../../assets/icons/price_tag-01.svg" class="mini-tag"/> -->Agendar Cita</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link item-toyota" routerLink="/taller-movil" (click)="noViewMenuPrincipal()">Taller Móvil</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link item-toyota"  routerLink="/tienda-virtual" (click)="noViewMenuPrincipal()"><!--<img src="../../../assets/icons/price_tag-01.svg" class="mini-tag"/> -->E-Commerce</a>
                            </li>
                            <!--<li class="nav-item">
                                <a class="nav-link item-toyota"  routerLink="/ofertas/filtros-de-combustible" (click)="noViewMenuPrincipal()"><img src="../../../assets/icons/price_tag-01.svg" class="mini-tag"/> Ofertas</a>
                            </li>-->
                            <div class="row separador-menu" *ngIf="userJson">
                            </div>
                            <li class="nav-item" *ngIf="userJson">
                                <a class="nav-link item-toyota"  routerLink="/tienda-virtual/carrito" (click)="noViewMenuPrincipal()">Tu Carrito</a>
                            </li>
                            <li class="nav-item" *ngIf="userJson">
                                <a class="nav-link item-toyota"  routerLink="/usuario/perfil" (click)="noViewMenuPrincipal()">Tu Perfil</a>
                            </li>
                        </ul>
                        <div class="row separador-menu_descarga">
                        </div>
                        <div class="row title-descarga">
                            Descarga nuestra App
                        </div>
                        <div class="row descarga">
                            <a href="https://apps.apple.com/bo/app/toyosa-s-a/id1591692324" target="_blank" class="col-6 item-out"><img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559776/production/plataforma-toyota/web/assets/icons/comunications/boton_apple_store.svg" alt="App Store Toyosa" class="icon-app"/></a>
                            <a href="https://play.google.com/store/apps/details?id=com.toyosa.toyosa_app" target="_blank" class="col-6  item-out"><img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559778/production/plataforma-toyota/web/assets/icons/comunications/boton_google_play.svg" alt="Play Store Toyosa" class="icon-app"/></a>
                        </div>
                        <!--<div class="row">
                            <a href="tel:591800175200" target="_blanck" class="is-linnkcc">
                                <img src="../../assets/icons/Icono_contact_bw-01.svg" class="rrss-menu-imgcc"/>
                            </a>
                        </div>-->
                        
                        <div class="row salir" *ngIf="userJson">
                            <a class="item-out" (click)="logout()"><img src="../../../assets/icons/logout.png" class="icon-out"/> Salir</a>
                        </div>
                        
                    </div>
                    <div class="col-4 menu-principal_back">
                        <a (click)="noViewMenuPrincipal()" class="opacity-block"></a>
                    </div>
                </div>     
            </div>
        </div>
    </div>    
</div>
