import { Component, HostListener, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MenuService } from 'src/app/services/catalogo/menu.service';
import {first} from 'rxjs/operators';
import { CatalogoService } from 'src/app/services/ecommerce/catalogo.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobileComponent implements OnInit {
  dataCompleta:Object=[];
  grupoModelos:Object=[];
  itemsGrupo:Object=[];
  active:number = 0;
  viewModelsBlock:boolean=false;
  search:any;
  viewSearchBlock:boolean=false;
  viewModelsCars:boolean = false;
  orientationTab;
  nombreGrupo:any;
  menuPrincipal:boolean=false;
  isCollapsedPostVenta:boolean = false;
  isCollapsedSobreNosotros:boolean = false;
  topHeader:number=0;

  /*Menu eCommerce */
  mostrareCommerce = false;
  /*Menu eCommerce */
  mostrarCatalogo = false;

  toggle = [true, true, true, true, true];

  /* info del usuario*/
  public userData: any;
  public userJson:any;
  nombreUsuario:string="";
  noMostrar=false;
  cantidadCarrito:any;

  /* web view */
  web=true;
  
  constructor(private router: Router, private vehiculosService: MenuService, private eCommerceService: CatalogoService) {

    this.obtenerVehiculos();
    this.active = 0;
    this.orientationTab = 'vertical';
    this.viewModelsCars = false;
    this.isCollapsedPostVenta =false;
    this.isCollapsedSobreNosotros = false;
  }

  ngOnInit(): void {
    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        scrollTo(0, 0);
        this.viewModelsBlock = false;
      }

      if (event instanceof NavigationEnd) {
        scrollTo(0, 0);
        this.viewModelsBlock = false;
        this.verificandoUsuario();
        const pathname = window.location.pathname;
        const elHeader: HTMLElement | null = document.getElementById('header-menu-desktop');

        let patheCommerce = pathname.startsWith("/tienda-virtual/");

        switch (patheCommerce) {
          case true:
            const valores = window.location.search;
          
            if(valores) {
              this.web=false;
              this.noMostrar = false;
              this.mostrareCommerce = false;
              this.mostrarCatalogo = false;
              
            } else {
              this.web=true;
              this.noMostrar = false;
              this.mostrareCommerce = true;
              this.mostrarCatalogo = false;
              if (elHeader) {
                elHeader.classList.add('back-gray');
              }
            }
            break;
          case false:
            switch (pathname) {
              case '/login':
                this.mostrareCommerce = false;
                this.mostrarCatalogo = false;
                this.noMostrar = true;
                break;
    
              case '/':
                this.mostrareCommerce = false;
                this.mostrarCatalogo = false;
                this.noMostrar = false;
                if (elHeader) {
                  elHeader.classList.add('back-white');
                }
                break;
    
              case '/tienda-virtual':
                this.noMostrar = false;
                this.mostrareCommerce = true;
                this.mostrarCatalogo = false;
                if (elHeader) {
                  elHeader.classList.add('back-gray');
                }
                break;

              case '/catalogo/toyota':
                  this.mostrarCatalogo = true;
                  this.mostrareCommerce = false;
                  this.noMostrar = false;
                  
                  if (elHeader) {
                      elHeader.classList.remove('back-white');
                  }
                break;
              case '/catalogo/lexus':
                  this.mostrarCatalogo = true;
                  this.mostrareCommerce = false;
                  this.noMostrar = false;
                  
                  if (elHeader) {
                      elHeader.classList.remove('back-white');
                  }
                break;
              case '/seminuevos':
                  this.mostrarCatalogo = true;
                  this.mostrareCommerce = false;
                  this.noMostrar = false;
                  
                  if (elHeader) {
                      elHeader.classList.add('back-white');
                  }
                break;
              default:
                this.noMostrar = false;
                this.mostrareCommerce = false;
                this.mostrarCatalogo = false;
                if (elHeader) {
                  elHeader.classList.add('back-white');
                }
                break;
            }
            break;
          default:
            break;
        }

        if( pathname == "/campañas/repuestos-navideños" || pathname =="/campa%C3%B1as/repuestos-navide%C3%B1os"){
          //console.log(pathname);
          this.noMostrar = false;
          this.mostrareCommerce = true;
          this.mostrarCatalogo = false;
          if (elHeader) {
            elHeader.classList.add('back-white');
          }
        }
      }

      if (event instanceof NavigationError) {
        scrollTo(0, 0);
        this.viewModelsBlock = false;
      }
    });
  }

  verificandoUsuario() {
    this.userData = localStorage.getItem('userPlataformaTY');
    this.userJson = JSON.parse(this.userData);
    localStorage.removeItem("toyocarrito");

    if(this.userJson != null){
        this.nombreUsuario = "Hola, "+(this.userJson['nombre'].split(' ')[0]);
        this.eCommerceService.verCarrito().pipe(first()).subscribe(
          data => {
            if(data) {
              if(data.detalle_productos) {
                if(data.detalle_productos.length > 0) {
                  localStorage.setItem('toyocarrito', JSON.stringify(data));
                  this.cantidadCarrito = data.detalle_productos.length;
                } else {
                  //this.cantidadCarrito = 0;
                }
              }
            }
            
          }, error => console.log(error)
        );
    } else {
      this.nombreUsuario = "Toyosa";
    }
  }

  viewMenuPrincipal() {
    this.menuPrincipal = true;
    this.search = '';
  }
  noViewMenuPrincipal() {
    this.menuPrincipal = false;
  }

  // Servicio que obtiene la lista de vehiculos
  obtenerVehiculos():void {
    /*this.vehiculosService.getAutomovilesAll().pipe(first()).subscribe(
        data => {
          this.grupoModelos = Object.keys(data);
          this.dataCompleta = data;
         
        }, error => {
          
        }
    );  */
  }
  obtenerItems(index: string) {
    this.nombreGrupo = index;
    this.viewModelsBlock = false;
    this.viewModelsCars = true;
   
  }


  viewModels() {
    if(this.viewModelsBlock){
      this.viewModelsBlock = false;
    } else {
      this.viewModelsBlock = true;
    }
  }

  noViewModels() {
    this.viewModelsBlock = false;
  }

  backModels() {
    this.viewModelsBlock = false;
    this.viewModelsCars = false;
  }
  backCars() {
    this.itemsGrupo = [];
    this.viewModelsBlock = true;
    this.viewModelsCars = false;
  }
  closeModels() {

  }
  closeCars() {
    this.itemsGrupo = [];
    this.viewModelsBlock = false;
    this.viewModelsCars = false;
  }
  logout(){
    this.userData = localStorage.getItem('userPlataformaTY');
    this.userJson = JSON.parse(this.userData);
    console.log("User: " + this.userJson);

    if(this.userJson != null){
      localStorage.removeItem('userPlataformaTY');
      localStorage.removeItem('sucursalPT');
      localStorage.removeItem('marcaPT');
      localStorage.removeItem('genericoPT');
      localStorage.removeItem('ciudadECPT');
      localStorage.removeItem('tarjeta');
      localStorage.removeItem('sucursalPT');
      this.router.navigate(['/']);      
      //this.location.back();
    } else {
      //this.logueado = true;
    }
  }
}
