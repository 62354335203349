<ng-template #contentcp let-c="close" let-d="dismiss">
    <div class="modal-body">
        <h4>¿En dónde recogerás tus compras?</h4>
        <div>
            Te ofrecemos una varidad de repuestos originales para tu vehículo.
        </div>
        <div class="row ciudades">
            <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                <label class="ciudades-lbl">Departamentos :</label> 
                <select placeholder="Seleccione un departamento" (change)="selectCiudad($event)" class="ciudades-select">
                    <option *ngFor="let item of ciudadesTiendas; index as i"  [value]="item['id_departamento']">
                        {{item['nombre_departamento']}}
                    </option>
                </select>
            </div>
            <div class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                <form [formGroup]="formulario" class="row pedido">
                    <label class="ciudades-lbl">Sucursales :</label>
                    <select class="ciudades-select" formControlName="sucursal">
                        <option *ngFor="let sucursal of dataCompletaBolivia" [value]="sucursal['id_sucursal']"><span class="suc-nombre">{{sucursal['nombre_sucursal']}}</span> - {{sucursal['direccion']}}</option>
                    </select>
                </form>
            </div>
            <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <a (click)="guardar()">Guardar</a>
            </div>
        </div>
        <div class="row" *ngIf="dataCompletaBolivia?.length > 0">
            <a class="view-map" (click)="viewMap()" *ngIf="verMapa"><img src="../../../assets/icons/sucursales.svg" class="pin-mapas"/> Ocultar mapa</a>
            <a class="view-map" (click)="viewMap()" *ngIf="!verMapa"><img src="../../../assets/icons/sucursales.svg" class="pin-mapas"/> Ver mapa</a>
        </div>
        <div *ngIf="verMapa">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                <agm-marker *ngFor="let sucursal of dataCompletaBolivia; index as i" [latitude]="sucursal['latitud']" [longitude]="sucursal['longitud']" [markerClickable]="true" (markerClick)="activarSucursal(dataCompletaBolivia[i])">
                    <agm-info-window>
                        <strong>{{sucursal['nombre_sucursal']}}</strong>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
    </div>          
</ng-template>

