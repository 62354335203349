import { NgModule } from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavHeaderModule } from "./nav-header/nav-header.module";
import { FooterModule } from "./footer/footer.module";
import { GtagModule } from 'angular-gtag';
import { AgmCoreModule } from '@agm/core';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { VehiculosToyotaComponent } from './vehiculos-toyota/vehiculos-toyota.component';
import { ChatModule } from './chat/chat.module';

@NgModule({
  declarations: [
    AppComponent,
    VehiculosToyotaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NavHeaderModule,
    FooterModule,
    ChatModule,
    GtagModule.forRoot({ trackingId: '344119194', trackPageviews: false }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBk6-zusArk27Q-a6ZPp44iLFmQG_ckXSA'
    }),
    SimpleNotificationsModule.forRoot()
  ],
  providers: [Title, Meta],
  bootstrap: [AppComponent]
})
export class AppModule { }
