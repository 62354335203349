import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
@Component({
  selector: 'app-identificacion-modelos',
  templateUrl: './identificacion-modelos.component.html',
  styleUrls: ['./identificacion-modelos.component.css']
})
export class IdentificacionModelosComponent implements OnInit {
  currentIndex: number = 0;
  itemsGrupo:any=[
    
  ];

  @ViewChild('buttonContainer', { static: false }) buttonContainer!: ElementRef;
  private touchStartX: number = 0;
  private touchEndX: number = 0;
  scrollPosition: number = 0;

  
  constructor() {
    this.goBrand(0);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.adjustSlidesWidth();
    }, 500);
  }

  adjustSlidesWidth() {
    const container = this.buttonContainer.nativeElement;
    const slides = container.querySelectorAll('.button-carousel-div');
    
    // Asegura que cada slide ocupe el 100% del ancho del contenedor
    slides.forEach((slide: HTMLElement) => {
      slide.style.width = `${container.clientWidth}px`;
    });
  }

  scrollLeft() {
    if (!this.buttonContainer) return;
    const container = this.buttonContainer.nativeElement;
    const slideWidth = container.clientWidth;
    
    container.scrollBy({ left: -slideWidth, behavior: 'smooth' });
    this.prevSlide();
  }

  scrollRight() {
    if (!this.buttonContainer) return;
    const container = this.buttonContainer.nativeElement;
    const slideWidth = container.clientWidth;
    
    container.scrollBy({ left: slideWidth, behavior: 'smooth' });
    this.nextSlide();
  }

  goToSlide(index: number) {
    this.currentIndex = index;

    // Calcula la posición del desplazamiento
    const container = this.buttonContainer.nativeElement;
    //const slideWidth = container.offsetWidth;
    const slideWidth = container.clientWidth;

    // Desplaza el contenedor a la posición correspondiente
    container.scrollTo({ left: slideWidth * index, behavior: 'smooth' });
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.touches[0].clientX;
  }

  onTouchMove(event: TouchEvent) {
    this.touchEndX = event.touches[0].clientX;
  }

  onTouchEnd() {
    const deltaX = this.touchEndX - this.touchStartX;

    // Detecta un swipe hacia la izquierda o derecha
    if (deltaX > 50) {
      // Swipe hacia la derecha
      this.prevSlide();
    } else if (deltaX < -50) {
      // Swipe hacia la izquierda
      this.nextSlide();
    }
  }

  prevSlide() {
    this.currentIndex = this.currentIndex > 0 ? this.currentIndex - 1 : this.itemsGrupo.length - 1;
  this.goToSlide(this.currentIndex);
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.itemsGrupo.length;
    this.goToSlide(this.currentIndex);
  }
  currentB:any= 0;
  goBrand(id:any) {
    this.currentB = id;

    if(id == 0) {
      this.itemsGrupo = [{
        imagen:"https://res.cloudinary.com/toyosa-sa/image/upload/v1738361489/production/toyota-bolivia/web/assets/images/takata/hilux-2005-2014.webp",
        marca:"Toyota",
        modelos:"Hilux",
        anio:"2005 - 2014"
      },
      {
        imagen:"https://res.cloudinary.com/toyosa-sa/image/upload/v1738361489/production/toyota-bolivia/web/assets/images/takata/rav4-2003-2005.webp",
        marca:"Toyota",
        modelos:"Rav4",
        anio:"2003 - 2005"
      },
      {
        imagen:"https://res.cloudinary.com/toyosa-sa/image/upload/v1738361489/production/toyota-bolivia/web/assets/images/takata/corolla-2002-2014.webp",
        marca:"Toyota",
        modelos:"Corolla",
        anio:"2002 - 2014"
      },
      {
        imagen:"https://res.cloudinary.com/toyosa-sa/image/upload/v1738361489/production/toyota-bolivia/web/assets/images/takata/yaris-2002-2013.webp",
        marca:"Toyota",
        modelos:"Yaris",
        anio:"2002 - 2013"
      },
      {
        imagen:"https://res.cloudinary.com/toyosa-sa/image/upload/v1738361490/production/toyota-bolivia/web/assets/images/takata/4runner-2011-2016.webp",
        marca:"Toyota",
        modelos:"4Runner",
        anio:"2011 - 2016"
      },
      {
        imagen:"https://res.cloudinary.com/toyosa-sa/image/upload/v1738361489/production/toyota-bolivia/web/assets/images/takata/fortuner-2008-2009.webp",
        marca:"Toyota",
        modelos:"Fortuner",
        anio:"2008 - 2009"
      }]
    }

    if(id == 1) {
      this.itemsGrupo = [
      {
        imagen:"https://res.cloudinary.com/toyosa-sa/image/upload/v1738361489/production/toyota-bolivia/web/assets/images/takata/gx460-2012-2015.webp",
        marca:"Lexus",
        modelos:"GX460",
        anio:"2012 - 2015"
      },
      {
        imagen:"https://res.cloudinary.com/toyosa-sa/image/upload/v1738361489/production/toyota-bolivia/web/assets/images/takata/is250-2010.webp",
        marca:"Lexus",
        modelos:"IS250",
        anio:"2010"
      }]
    }
  }

}
