import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  //@ViewChild('contentcp') templateRef: TemplateRef<any> | undefined;
  title = 'app-web-plataforma-toyota';

  codeRedEnlaceInput:any;
  
  constructor(private modalService: NgbModal, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document, private titleService: Title, private meta: Meta) {

      let titulo = "TOYOSA S.A. - Repuestos | Venta de Vehículos | Agendado de citas";

      this.titleService.setTitle(titulo);
      this.meta.addTags([
        { name: 'description', content: 'Toyosa es la empresa mas importante del sector automotriz en Bolivia; marca de alto nivel Toyota. Vehículos con calidad y garantía inigualable, apuntamos a la elegancia, perfección y buen trato hacia nuestros clientes.' },
        { property: 'og:title', content: titulo },
        { property: 'og:description', content: 'Toyosa es la empresa mas importante del sector automotriz en Bolivia; marca de alto nivel Toyota. Vehículos con calidad y garantía inigualable, apuntamos a la elegancia, perfección y buen trato hacia nuestros clientes.' } ,
        { property: 'og:image', content: 'https://www.toyosa.com/assets/images/popup/fondo_desktop.jpg' },
        { name: 'keywords', content: 'toyota, lexus, toyosa, repuestos, repuestos Bolivia, repuestos Toyota, repuestos Toyota originales, servicio técnico, ecommerce de repuestos, repuestos toyota bolivia, repuestos lexus bolivia, vehiculos, auto, autos, vagoneta, vagonetas, camioneta, camionetas, buses, repuestos, corolla, corolla hibrido, yaris, fortuner, land cruiser, 4runner, rav4, landcruiser prado, rush, hilux, hilux nf, hilux trd, coaster, hiace, cotizaciones, cotizador, comparador, modelos, santa cruz, la paz, cochabamba, potosi, sucre, chuquisaca, cobija, beni, tarija, trinidad, oruro' },
        { property: 'og:url', content: 'https://www.toyosa.com' },
      ]);

      this.addSchema();
      this.addAsyncAttributeToLinks();
  }
  addAsyncAttributeToLinks() {
    const linkElements = document.querySelectorAll('link[rel="stylesheet"]');
    linkElements.forEach((link: any) => {
      link.setAttribute('async', '');
    });
  }

  /*ngAfterViewInit() {
      this.openModal(this.templateRef);
      setTimeout(() => {
          this.modalService.dismissAll();
      }, 5000);
  }

  openModal(content:any) {
    this.modalService.open(content,{ centered: true, size: 'lg' });
  }*/

  generarSerieRedEnlace(longitud: number) {
      let a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('')
      let b = []
      for (let i = 0; i < longitud; i++) {
        let j: any = (Math.random() * (a.length - 1)).toFixed(0)
        b[i] = a[j]
      }
      return b.join('');
  }

 
  addSchema(){
    let elemento = this._document.getElementById('toyosahead');
    let script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
    script.text = `
    {
      "@context": "https://schema.org",
      "@type": "AutomotiveBusiness",
      "image": [
        "https://res.cloudinary.com/toyosa-sa/image/upload/v1637699312/production/opengraph/toyosa.jpg"
       ],
      "name": "TOYOSA S.A. - Repuestos | Vehículos | Agendado de citas",
      "geo": [
        {
        "@type": "GeoCoordinates",
        "latitude": -17.75388,
        "longitude": -63.17713
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -17.7902549,
        "longitude": -63.1943218
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -17.3515889,
        "longitude": -63.2478205
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -17.377165,
        "longitude": -66.144357
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -17.372473,
        "longitude": -66.160529
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -17.376175,
        "longitude": -66.144054
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -16.52015,
        "longitude": -68.11544
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -16.500467,
        "longitude": -68.133689
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -16.52035,
        "longitude": -68.16794
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -17.969156,
        "longitude": -67.117416
      	},
        {
        "@type": "GeoCoordinates",
        "latitude": -19.5709335,
        "longitude": -65.7524099
      	}
      ],
      "url": "https://www.toyosa.com/",
      "telephone": "+591800175200"
    }
    `;
    this._renderer2.appendChild(elemento, script);
  }

}
