import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { CatalogoService } from 'src/app/services/ecommerce/catalogo.service';

@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.css']
})
export class MenuPrincipalComponent implements OnInit {
  viewSubmenuVenta:boolean = false;
  /* info del usuario*/
  public userData: any;
  public userJson:any;
  nombreUsuario:string="";
  cantidadCarrito:any;
  sucursalCarrito:any;
  web = true;
  activarCiudad:any;
  ciudadPedido:any;

  isScrolled = false;
  @Input() usuario:any;

  constructor(private router: Router, private eCommerceService: CatalogoService) {
    
  }

  ngOnInit(): void {
    this.verificandoUsuario();
    this.router.events.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.verificandoUsuario();
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset;
    this.isScrolled = offset > 50; // Cambia el valor según lo necesites
  }

  verificandoUsuario() {
      localStorage.removeItem("toyocarrito");
      this.userData = localStorage.getItem('userPlataformaTY');
      this.userJson = JSON.parse(this.userData);
  
      if(this.userJson != null){
          this.nombreUsuario = "Hola, "+(this.userJson['nombre'].split(' ')[0]);
          this.eCommerceService.verCarrito().pipe(first()).subscribe(
            data => {
              //console.log(data);
              if(data.detalle_productos) {
                if(data.detalle_productos.length > 0) {
                  localStorage.setItem('toyocarrito', JSON.stringify(data));
                  this.cantidadCarrito = data.detalle_productos.length;
                  this.sucursalCarrito = data.detalle_productos[0].nombre_sucursal;
                } else {
                  //this.cantidadCarrito = 0;
                }
              }
              
            }, error => console.log(error)
          );
      } else {
        this.nombreUsuario = "Toyosa";
      }
    }

  viewSubmenu(): void {
    if (this.viewSubmenuVenta){
      this.viewSubmenuVenta = false;
    } else {
      this.viewSubmenuVenta = true;
    }
  }
  noviewSubmenu(): void {
    this.viewSubmenuVenta = false;
  }

}
