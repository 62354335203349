import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterRoutingModule } from './footer-routing.module';
import { FooterComponent } from './footer.component';
import { ChatModule } from '../chat/chat.module';


@NgModule({
  exports: [FooterComponent],
  declarations: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    FooterRoutingModule,
    ChatModule
  ]
})
export class FooterModule { }
