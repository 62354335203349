<div class="identificacion-modelos">
    <h2 class="identificacion-title">Identificación de modelos</h2>
    <p class="identificacion-descrip">Revisa si tu Toyota es uno de los modelos habilitados para esta campaña</p>
    <div class="row opciones-marcas">
        <a class="col-6" [ngClass]="{'active': 0 === currentB}" (click)="goBrand(0)">Modelos Toyota</a>
        <a class="col-6" [ngClass]="{'active': 1 === currentB}" (click)="goBrand(1)">Modelos Lexus</a>
    </div>
    <div class="carousel-container">
        <button class="scroll-button left" (click)="scrollLeft()" *ngIf="itemsGrupo.length > 1">
            &#10094;
        </button>
        <div class="button-carousel" #buttonContainer (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd()">
          <div *ngFor="let caracteristica of itemsGrupo" class="row button-carousel-div">  
                <div class="carrousel-datos-modelo">
                    <img [src]="caracteristica.imagen"/>
                    <div class="row pl-1 mt-2 modelo">
                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div class="nombre-modelo">Marca</div>
                            <div class="data-modelo">{{caracteristica.marca}}</div>
                        </div>
                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div class="nombre-modelo">Modelo</div>
                            <div class="data-modelo">{{caracteristica.modelos}}</div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div class="nombre-modelo">Año</div>
                            <div class="data-modelo">{{caracteristica.anio}}</div>
                        </div>
                    </div>
                </div>
          </div>
        </div>
        <button class="scroll-button right" (click)="scrollRight()"  *ngIf="itemsGrupo.length > 1">
            &#10095;
        </button>
        <!-- Indicadores (bullets) -->
        <div class="carousel-indicators">
          <span
            *ngFor="let caracteristica of itemsGrupo; let i = index"
            [ngClass]="{'active': i === currentIndex}"
            (click)="goToSlide(i)"
          ></span>
        </div>
    </div>
</div>