<div class="desktop">
    <div id='header' class="no-mobile">
        <div id="header-menu-desktop"  class="position-fixed menu-toyosa" [ngClass]="{'scrolled': isScrolled}">
            <div class="container" >
                <div class="navbar-expand-lg navbar-light d-inline-flex header-menu">
                    <a class="float-start navbar-brand " routerLink="">
                        <img src="https://res.cloudinary.com/toyosa-sa/image/upload/v1713559522/production/plataforma-toyota/web/assets/icons/comunications/logo_toyosa_negro.svg" width="145" height="35" alt="Logo toyosa" class="mt-2 logo"/>
                    </a> 
                    <ul class="navbar navbar-nav justify-content-center">
                        <li ngbDropdown class="nav-item">
                            <a class="nav-link item-toyota" (click)="viewSubmenu()"  >Vehículos nuevos</a>                  
                        </li>
                        <div class="vr mx-3"></div>
                        <li ngbDropdown class="nav-item"  >
                            <button class="btn btn-primary btn-menu-toyosa" ngbDropdownToggle  >Servicio Técnico</button>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem routerLink='servicio-tecnico/cotizar-mantenimientos'>Cotizar Mantenimientos</button>
                                <button ngbDropdownItem routerLink='servicio-tecnico'>Agendar Cita</button>
                                <button ngbDropdownItem routerLink='taller-movil'>Taller Móvil</button>
                                <button ngbDropdownItem routerLink='takata'>Recall Takata</button>
                            </div>
                        </li>
                        <div class="vr mx-3"></div>
                        <li ngbDropdown class="nav-item"  >
                            <a class="nav-link item-toyota" routerLink='/tienda-virtual' id="mundotoyota" (click)="noviewSubmenu()"  ><img src="../../assets/icons/price_tag-01.svg" width="20" height="18" class="mini-tag"/> E-commerce</a>
                        </li>
                    </ul>
                                        
                    <ul class="navbar-nav justify-content-center mt-2" *ngIf="!userJson; else userData">
                        <li ngbDropdown class="nav-item">
                            <a class="nav-link item-toyota" routerLink="/usuario/registro">Registro</a>                  
                        </li>
                        <div class="vr mx-3"></div>
                        <li ngbDropdown class="nav-item">
                            <a class="nav-link item-toyota" routerLink="/login">Login</a>                  
                        </li>
                    </ul>
                    <ng-template #userData>
                        <ul class="navbar-nav justify-content-center menu-profile mt-2">
                            <li ngbDropdown class="nav-item">
                                <a class="d-flex py-2 px-4 rounded-5 item-profile" routerLink="/usuario/perfil"><img src="../../assets/icons/profile-user.svg" class="mx-2 img-profile"/>{{nombreUsuario}} </a>                  
                            </li>
                        </ul>
                    </ng-template> 
                </div>
            </div>
            <div class="submenu-plataforma" *ngIf="viewSubmenuVenta">
                <div class="container">
                    <div class="row">
                        <nav class="navbar navbar-expand-lg navbar-light justify-content-center">
                            <div class="navbar-nav">
                                <a class="dropdown-item nav-link item-plataforma" href="https://www.toyota.bo/" target="_blank" (click)="noviewSubmenu()">Toyota  <img src="../../assets/icons/external-link-white.svg" class="icono-link-externo"/></a>
                                <a class="dropdown-item nav-link item-plataforma" href="https://www.lexus.com.bo/es.html" target="_blank" (click)="noviewSubmenu()">Lexus  <img src="../../assets/icons/external-link-white.svg" class="icono-link-externo"/></a>
                            </div>
                        </nav>
                    </div>
                </div>
                
            </div>
            
        
        </div>
    </div>
</div>