import { Component, OnInit, HostListener, ViewChild, TemplateRef, Renderer2, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {MenuService} from '../services/catalogo/menu.service';
import { CatalogoService } from '../services/ecommerce/catalogo.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {

  dataCompleta: any = [];
  grupoModelos: any = [];
  itemsGrupo: any = [];
  active = 0;
  viewModelsBlock = false;
  viewSubmenuVenta:boolean = false;

  topHeader = 0;

  noMostrar=false;
  /*Menu eCommerce */
  mostrareCommerce = false;
  /*Menu eCommerce */
  mostrarCatalogo = false;

  toggle = [true, true, true, true, true];
  isLogin = false;
  /* info del usuario*/
  public userData: any;
  public userJson:any;
  nombreUsuario:string="";
  cantidadCarrito:any;
  sucursalCarrito:any;
  web = true;
  activarCiudad:any;
  ciudadPedido:any;

  /* Ciudad y sucursal del pedido */
  @ViewChild('contentcp') templateRef: TemplateRef<any> | undefined;
  ciudadesTiendas=[];
  dataCompletaBolivia:any=[];
  verCiudad:boolean;
  verMapa=false;

  lat = -17.4911607;
  lng = -63.9826885;
  zoom = 6;

  @ViewChild('fondoMenu') fondoDiv!: ElementRef;
  

  constructor( private router: Router, private renderer: Renderer2,private modalService: NgbModal, private eCommerceService: CatalogoService,
    private repuestosService: CatalogoService, private sucursalesService: CatalogoService) {
      this.active = 0;
      this.activarCiudad = false;

      this.verCiudad = false;
      
      /* Ciudad y sucursal del pedido */
      this.repuestosService.getCiudadesTiendas().pipe(first()).subscribe(
        data => {
          this.ciudadesTiendas = data;
        }, error => console.log(error)
      );
      
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        // Aquí actualizas el contenido de tu componente o haces cualquier acción adicional
        //console.log('Ruta actual:', event.urlAfterRedirects);
        if (event.urlAfterRedirects == "/servicio-tecnico/cotizar-mantenimientos?app=1") {
          this.web = false;
        }
        this.actualizarContenido();

        /*scrollTo(0, 0);
         // Reinicia los estados del menú antes de aplicar cada condición
         this.resetMenu();
         switch (event.urlAfterRedirects) {
          case '/':
            this.menuhome();
            break;
          
          case '/login':
            this.noMostrar = true;
            this.mostrareCommerce = false;
            this.mostrarCatalogo = false;
            break;
          
          case '/login/recuperar':
            this.noMostrar = true;
            this.mostrareCommerce = false;
            this.mostrarCatalogo = false;
            break;
          
          case '/aplicacion':
          case '/usuario/registro':
            this.menuTransparente();
            break;

          default:
            if (event.urlAfterRedirects.includes('/tienda-virtual')) {
              this.menueCommerce();
            }
            break;
        }*/
      });
  }
  resetMenu(): void {
    this.noMostrar = false;
    this.mostrareCommerce = false;
    this.mostrarCatalogo = false;
  }

  menuhome(){
    console.log("*** Home");
    this.mostrareCommerce = false;
    this.mostrarCatalogo = false;
    this.noMostrar = false;
    this.renderer.setStyle(this.fondoDiv.nativeElement, 'background-color', 'transparent'); // Color de fondo para inicio
  }

  menueCommerce() {
    this.noMostrar = false;
    this.mostrareCommerce = true;
    this.mostrarCatalogo = false;
    this.renderer.setStyle(this.fondoDiv.nativeElement, 'background-color', '#ffffff');
    
  }

  menuTransparente() {
    this.mostrareCommerce = false;
    this.mostrarCatalogo = false;
    this.noMostrar = false;
  
  }

  actualizarContenido():void {
    this.viewModelsBlock = false;
        this.verificandoUsuario();

        
        const pathname = window.location.pathname;
        const elHeader: HTMLElement | null = document.getElementById('header-menu-desktop');

        let pathCatalogoToyota = pathname.startsWith("/toyota/");
        let pathCatalogoLexus = pathname.startsWith("/lexus/");
        let pathCatalogoBusqueda = pathname.startsWith("/catalogo/busqueda/");
        let patheCommerce = pathname.startsWith("/tienda-virtual/");
        let pathCotizador = pathname.startsWith("/servicio-tecnico/cotizar-mantenimientos?app=1");
        //console.log(pathname);
        
        switch (patheCommerce) {
          case true:
              this.noMostrar = false;
              this.mostrareCommerce = true;
              this.mostrarCatalogo = false;
              if (elHeader) {
                elHeader.classList.add('back-white');
              }

              let pathnameC = pathname.startsWith("/tienda-virtual/pago-tarjeta?token");
              
              //console.log("Ingresando... "+ pathnameC);
              
              switch (pathnameC) {
                case true:
                  let elHeaderA: HTMLElement | null = document.getElementById('menu-mobile');
                  if (elHeaderA) {
                    elHeaderA.classList.add('aplicacion');
                  }
                  break;
                case false:
                  let elHeaderB: HTMLElement | null = document.getElementById('menu-mobile');
                  if (elHeaderB) {
                    elHeaderB.classList.add('web');
                  }
                  break;
              
                default:
                  const elHeaderW: HTMLElement | null = document.getElementById('menu-mobile');
                  if (elHeaderW) {
                    elHeaderW.classList.add('web');
                  }
                  break;
              }
            break;

            
          case false:
            switch (pathname) {
              case '/login':
                this.mostrareCommerce = false;
                this.mostrarCatalogo = false;
                this.noMostrar = true;
                break;
              
              case '/login/recuperar':
                if (elHeader) {
                  elHeader.classList.remove('back-white');
                  //elHeader.classList.add('back-opacity');
                }
                break;
    
              case '/':
                this.mostrareCommerce = false;
                this.mostrarCatalogo = false;
                this.noMostrar = false;
                if (elHeader) {
                  //elHeader.classList.remove('back-white');
                  elHeader.classList.add('back-white');
                }
                break;

              
    
              case '/tienda-virtual':
                this.noMostrar = false;
                this.mostrareCommerce = true;
                this.mostrarCatalogo = false;
                if (elHeader) {
                  elHeader.classList.add('back-white');
                }
                break;

              case '/campañas':
                console.log("ingresa acá");
                  this.noMostrar = false;
                  this.mostrareCommerce = true;
                  this.mostrarCatalogo = false;
                  if (elHeader) {
                    elHeader.classList.add('back-white');
                  }
                  break;
              
              case '/catalogo/toyota':
                  this.mostrarCatalogo = true;
                  this.mostrareCommerce = false;
                  this.noMostrar = false;
                  
                  if (elHeader) {
                      elHeader.classList.remove('back-white');
                  }
              break;

              case '/catalogo/lexus':
                  this.mostrarCatalogo = true;
                  this.mostrareCommerce = false;
                  this.noMostrar = false;
                  
                  if (elHeader) {
                      elHeader.classList.remove('back-white');
                  }
              break;
              case '/seminuevos':
                  this.mostrarCatalogo = true;
                  this.mostrareCommerce = false;
                  this.noMostrar = false;
                  
                  if (elHeader) {
                      elHeader.classList.add('back-white');
                  }
              break;
              default:
                this.noMostrar = false;
                this.mostrareCommerce = false;
                this.mostrarCatalogo = false;
                if (elHeader) {
                  elHeader.classList.add('back-white');
                }
                break;
            }
            break;
          default:
            break;
        }


        if( pathCatalogoToyota == true ){
            console.log(pathCatalogoToyota);
            this.mostrarCatalogo = true;
            this.mostrareCommerce = false;
            this.noMostrar = false;
            if (elHeader) {
                elHeader.classList.remove('back-white');
            }
        }

        if( pathCatalogoLexus == true ){
          console.log(pathCatalogoLexus);
          this.mostrarCatalogo = true;
          this.mostrareCommerce = false;
          this.noMostrar = false;
          if (elHeader) {
              elHeader.classList.remove('back-white');
          }
        }

        if( pathCatalogoBusqueda == true ){
          console.log(pathCatalogoLexus);
          this.mostrarCatalogo = true;
          this.mostrareCommerce = false;
          this.noMostrar = false;
          if (elHeader) {
              elHeader.classList.remove('back-white');
          }
        }

        /*if( pathname == "/campañas/repuestos-navideños" || pathname =="/campa%C3%B1as/repuestos-navide%C3%B1os"){
          //console.log(pathname);
          this.noMostrar = false;
          this.mostrareCommerce = true;
          this.mostrarCatalogo = false;
          if (elHeader) {
            elHeader.classList.add('back-white');
          }
        }*/
        if( pathname == "/aplicacion"){
          this.mostrareCommerce = false;
          this.mostrarCatalogo = false;
          this.noMostrar = false;
          if (elHeader) {
              elHeader.classList.add('back-transparent');
          }
        }
       
  }

  @HostListener('window:scroll', ['$event'])
  myScroll($event:any) {
    this.topHeader = window.scrollY;

    if(this.topHeader > 0) {
      let elementDOM: any | null = document.getElementById("header");
      elementDOM.style.top = "0px !important";
    }
  }

  onSelectOption(option: any) {
    console.log(`Opción seleccionada: ${option}`);
    this.router.navigate([option]).then(() => {
      window.location.reload();
    });
  }

  ciudadDelPedido() {
    let ciudadData:any = localStorage.getItem('ciudadLiteralECPT');
    let ciudadJson = JSON.parse(ciudadData);
    this.ciudadPedido = JSON.parse(ciudadJson);

    if(ciudadJson != null){
      
      return ciudadJson;
    }
  }

  verciudad(){
    //this.activarCiudad = true;
    this.openModal(this.templateRef);
    /*setTimeout(() => {
      if(this.ciudadDelPedido()){
        this.activarCiudad = false;
      } 
    }, 7000);*/
    
  }

  verificandoUsuario() {
    localStorage.removeItem("toyocarrito");
    this.userData = localStorage.getItem('userPlataformaTY');
    this.userJson = JSON.parse(this.userData);

    if(this.userJson != null){
        this.nombreUsuario = "Hola, "+(this.userJson['nombre'].split(' ')[0]);
        this.eCommerceService.verCarrito().pipe(first()).subscribe(
          data => {
            //console.log(data);
            if(data.detalle_productos) {
              if(data.detalle_productos.length > 0) {
                localStorage.setItem('toyocarrito', JSON.stringify(data));
                this.cantidadCarrito = data.detalle_productos.length;
                this.sucursalCarrito = data.detalle_productos[0].nombre_sucursal;
              } else {
                //this.cantidadCarrito = 0;
              }
            }
            
          }, error => console.log(error)
        );
    } else {
      this.nombreUsuario = "Toyosa";
    }
  }

  redireccionar(ruta:string){
    const elHeader: HTMLElement | null = document.getElementById('header-menu-desktop');
    switch (ruta) {
      case '/login':
        this.noMostrar = true;
        break;

      case '/':
        this.noMostrar = false;
        if (elHeader) {
          elHeader.classList.add('back-white');
        }
        
        break;

      default:
        this.noMostrar = false;
        if (elHeader) {
          elHeader.classList.add('back-white');
        }
        break;
    }
  }

  // Servicio que obtiene la lista de vehiculos
  obtenerVehiculos(): void {
    /*this.vehiculosService.getAutomovilesAll().pipe(first()).subscribe(
        data => {
          this.grupoModelos = Object.keys(data);
          this.dataCompleta = data;
          this.obtenerItems(this.dataCompleta[this.grupoModelos[0]]);
          localStorage.setItem('vehiculos-header', JSON.stringify(data));
        }, error => {
        }
    );*/
  }

  obtenerItems(index: string): void {
    this.itemsGrupo = this.dataCompleta[index];
  }


  viewSubmenu(): void {
    if (this.viewSubmenuVenta){
      this.viewSubmenuVenta = false;
    } else {
      this.viewSubmenuVenta = true;
    }
  }
  noviewSubmenu(): void {
    this.viewSubmenuVenta = false;
  }

  viewModels(): void {
    if (this.viewModelsBlock){
      this.viewModelsBlock = false;
    } else {
      this.viewModelsBlock = true;
    }
  }

  noViewModels(): void {
    this.viewModelsBlock = false;
  }


  openBackDropCustomClass(content:any) {
    this.modalService.open(content, {backdropClass: 'light-backdrop'});
  }

  enableDisableCiudades(idx:any, opc:any) {

    if ( this.toggle[idx] ) {
      this.toggle[idx] = false;
      let cnt = 0;
      this.toggle.forEach( e => {
        if ( cnt !== idx ) {
          this.toggle[cnt] = true;
        }
        cnt++;
      });
    } else {
      this.toggle[idx] = true;
    }
    setTimeout(() => {
      this.modalService.dismissAll();
    }, 1000);
    
  }

  /* PEDIDOS */

  viewMap() {
    if (this.verMapa) {
      this.verMapa = false;
    } else{
      this.verMapa = true;
    }
  }

  

  openModal(content:any) {
    this.modalService.open(content, { size: 'lg' });
  }

  selectCiudad(event:any){
    const ciudad:any = event.target.value;
    let literal = "";
    for (let ic = 0; ic < this.ciudadesTiendas.length; ic++) {
      if(this.ciudadesTiendas[ic]['id_departamento'] == ciudad){
        literal = ""+this.ciudadesTiendas[ic]['nombre_departamento'];
      }
    }
    
    localStorage.setItem('ciudadECPT', JSON.stringify(ciudad));
    localStorage.setItem('ciudadLiteralECPT', JSON.stringify(literal));
    let userData:any = localStorage.getItem('userPlataformaTY');
    let userJson = JSON.parse(userData);

    if(userJson != null){
      let datos = {
        _method:'PUT',
        id_ciudad:ciudad
      };
      this.repuestosService.postCiudadPedido(datos).subscribe( data => {
  
      }, error => {
          console.log(error);
      });
    } else {
    }
    
    
    this.verificarSucursales();
  }

  verificarSucursales(){
    let ciudadData:any = localStorage.getItem('ciudadECPT');
    let ciudadJson = JSON.parse(ciudadData);

    if(ciudadJson != null){
      this.verCiudad = false;
      this.sucursalesService.getPinesCiudad(ciudadJson).pipe(first()).subscribe(
        data => {
          this.dataCompletaBolivia = data;
        
        }, error => {
        }
      ); 
    } else {
      this.verCiudad = true;
      this.sucursalesService.getCiudadesTiendas().pipe(first()).subscribe(
        data => {
          this.ciudadesTiendas = data;
          this.openModal(this.templateRef);
        }, error => console.log(error)
      );
    }
  }

  

  
}
